import * as React from 'react';
import { useDispatch,useSelector } from 'react-redux'
import ReactInputVerificationCode from 'react-input-verification-code';
import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVerified } from '../../actions/userActions';

import "./VerificationForm.css"

const VerificationForm = () => {

  const navigate  = useNavigate();
  const dispatch = useDispatch();


  const userEmail =  localStorage.getItem('userEmail')

  const [code , setCode] = useState([]);



  


  const submitHandler = () => {

     dispatch(getVerified(code))
}


  return (

    <Flex pl={8} py={4} flex={1} pt={12}   justify={'center'} className="register-form-reg">
      <Stack >
        
            <ReactInputVerificationCode placeholder='' autoFocus={true} length={5} onCompleted={(code)=>{setCode(code)}}/>
            <Button bg="#006AFF" colorScheme={'blue'} variant={'solid'} onClick={()=>{submitHandler()}}>
               Send
          </Button>
        
        <Stack >

          {/* if the user prees resend then we will do a request to resend a code 
            and we will make the user stay at this page untill he enter the write code  */}
          <Button bg="#006AFF" colorScheme={'blue'} variant={'solid'} onClick={()=>{}}>
               Resend
          </Button>
        </Stack>
      </Stack>
    </Flex>
   
  )
}


export default VerificationForm
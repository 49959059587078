import React from 'react'
import {HiOutlinePhone} from "react-icons/hi"
import {CgMail } from "react-icons/cg"
import {ImLocation} from "react-icons/im"
import {Image} from '@chakra-ui/react'

export default function Footer() {
  return (
    <div style={{backgroundColor:'rgb(25 28 52)',height:'250px',overflow:'hidden'}} className='pe-4'>
    <div className='container'>
         <div className='row pt-4'>
               <div className='col-4 d-flex flex-column justify-content-between  ' style={{overflow:'hidden'}}>
                    <div>
                        <Image
                          style={{width:'150px',height:'100px' ,objectFit:'fill'}}
                          className=""
                          
                          src={require('../../Images/SiteOfficialLogo.svg').default}
                        />
                    </div>
                    <div>
                      <p className='font-size-mobile-footer' style={{color:'white'}}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                        sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
                        no sea</p>
                    </div>
                                      
                </div>
                <div className='col-4  d-flex flex-column justify-content-between  align-items-center '>
                    <div>
                        <ul style={{color:'white'}}>
                         <li> <h1 className='font-size-mobile-select font-size-desk-select mb-3'  style={{color:'white'}}>Direct Link</h1></li>
                        <li className='font-size-mobile-footer'> Home Page</li>
                        <li className='font-size-mobile-footer'> Buy</li>
                        <li className='font-size-mobile-footer'> Companies</li>
                        <li className='font-size-mobile-footer'> Contact Us</li>
                        <li className='font-size-mobile-footer'> Privacy and Policy</li>
                      </ul>
                    </div>
                </div>
                <div className='col-4  d-flex flex-column justify-content-between align-items-center'>
                    <div>
                        <ul style={{color:'white'}}>
                         <li> <h1 className='font-size-mobile-select font-size-desk-select mb-3'  style={{color:'white'}}>Contact Us</h1></li>
                        <li className='font-size-mobile-footer d-flex align-items-center' ><span className='me-4'><ImLocation/></span> New Caior, Caior, Egypt</li>
                        <li className='font-size-mobile-footer d-flex align-items-center' ><span className='me-4'><HiOutlinePhone/></span> 12334235123</li>
                        <li className='font-size-mobile-footer d-flex align-items-center' ><span className='me-4'><CgMail /></span>Example@gmail.com</li>
                     
                      </ul>
                    </div>
                </div>
                
         </div>
    </div>
  </div>
  )
}

import React from 'react'
import Footer from "../../components/Footer/Footer"
import NavBar from "../../components/Header/Navbar"
import {
    Stack,
    Flex,
    Container,
    Heading,
    Box,
    VStack,
    Text,
    } from '@chakra-ui/react';

export default function PrivacyPolicy() {
    return (
        <>
            <NavBar/>
            <Flex 
                height={'60vh'} 
                backgroundColor="#DCE5E4"
                overflow={'hidden'}
                >
                  <Flex color='white'  
                        width={'full'}
                        marginTop={'50'}
                        h={'50vh'} height={'63vh'}  
                        direction={{ base: 'column-reverse', md: 'row' }} 
                       
                        >
                        <Box flex={'1'}  height={'full'} >
                        <Flex
                                className='easymove'
                                w={'50wh'}
                                h={'full'}
                                z-zIndex={'-2'}
                                justifyContent={'center'}
                                backgroundImage={
                                require('../../Images/about.svg').default
                                }
                            
                                backgroundRepeat={'no-repeat'}
                                backgroundSize={'cover'}
                                backgroundPosition={'center center'}>
                                    
                        </Flex>
                        </Box>
                        <Box  flex={'1'}>
                        <Flex
                            className='easymove'
                            w={'50wh'}
                            height={'full'}
                            justifyContent={'center'}
                        >
                                
                                    <VStack align={'center'} justifyContent={'center'} alignItems={'center'}>
                                        <Text  color={'black'} fontSize={'30'} fontWeight={'bold'} marginBottom={'4'}>Find Your Place</Text>
                                        <Text color={'black'} fontSize={'22'}>Here The Buyer Meets The Seller</Text>
                                        <Text color={'black'} fontWeight={'bold'} fontSize={'25'}>Join Us</Text>
                                    </VStack>
                            
                                
                            </Flex>
                    </Box>
                    </Flex>
            </Flex>
            <Stack spacing={4} mt={5} mb={5} as={Container} maxW={'5xl'} textAlign={'center'}>
                <Heading 
                    bg={""}
                    fontSize={'4xl'}
                    fontFamily={"'Poppins', sans-serif !important"}
                    fontWeight="500"
                >
                    Privacy
                </Heading>
                <Text color={'#151617'} fontSize={'xl'}fontFamily={"'Poppins', sans-serif !important"} >
               
                <h1>1. TERMS</h1>
                
                <div>
                 <h2>  1.1	In these terms and conditions for users (“Terms of Use”) the terms “we", "our", "us" means Estate GPS Egypt LLC and its subsidiaries, employees, officers, agents, affiliates or assigned parties.</h2>
                1.2	"APP" refers to estategps.com.	
                1.3	By accessing and using this Website, you are agreeing to be bound by the APP’s Terms of Use and the Privacy Policy (together the “Terms”), all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these Terms, your sole option is to immediately cease your use of this Website. The materials contained in this Website are protected by applicable copyright and trademark law.
                1.4	You may not use the App and may not accept these Terms if (a) you are not of eighteen (18) years of age, or (b) you are a person who is either barred or otherwise legally prohibited from receiving or using the App under the laws of the country in which you are a resident or from which you access or use the app.
                1.5	These Terms are effective between you and us as of the date you accept these Terms, and you do so by default through the use of the App.
                2. USE LICENSE & RESTRICTIONS
                2.1	Permission is granted to temporarily download copies of the materials (information or software) on the App for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                a)	modify or copy the materials;
                b)	use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                c)	attempt to decompile or reverse engineer any software contained on the app;
                d)	remove any copyright or other proprietary notations from the materials; or
                e)	Transfer the materials to another person or "mirror" the materials on any other server.
                2.2	In accessing or using our app you irrevocably agree and undertake to ensure that you will not:
                a)	use any automated device, software process or means to access, retrieve, scrape, or index our app or any content on our app;
                b)	use any device, software, process or means to interfere or attempt to interfere with the proper working on our app;
                c)	undertake any action that will impose a burden or make excessive traffic demands on our infrastructure that we deem, in our sole discretion to be unreasonable or disproportionate app usage;
                d)	use or index any content or data on our app for purposes of:
                i.	constructing or populating a searchable database of properties,
                ii.	building a database of property information; or
                iii.	competing with us in any manner that we have not specifically authorized;
                e)	transmit spam, chain letters, contents, junk email, surveys, or other mass messaging, whether commercial in nature or not;
                f)	use our app or any content from our app in any manner which we in our sole discretion determine as not reasonable and/or not for the purpose which it is made available;
                g)	violate the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right;
                h)	pose as any person or entity or attempt to solicit money, passwords or personal information from any person;
                i)	act in violation of any such terms or other condition posed by us or any applicable law;
                j)	reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptation of, publicly display, sell, trade, or in any way exploit our app or any content on our app, except as expressly authorized by us; or
                k)	Transmit or attempt to transmit any computer viruses, worms, defects or other items of a destructive manner.
                2.3	We reserve the right to exercise whatever means we deem necessary to prevent unauthorized access to our use of the app, including but not limited to, instituting technological barriers, or reporting your conduct to any person or entity.
                2.4	This license shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.



                </div>


                3. YOUR RESPONSIBILITIES
                3.1	We are not an estate agency and we provide a service whereby agents may market and you may view property details (“Details”) together with other content hosted and developed by us. Agents and third parties are responsible for preparing the Details and fielding enquiries directly from you. We do not get involved in any communications between you and agents and we do not participate in any part of the transaction.
                3.2	Details are hosted by us in good faith but are produced directly by agents and/or third parties and have not been verified by us. You are responsible for making your own enquiries and we provide no guarantee and accept no responsibility for the accuracy or completeness of any information contained within the Details.
                3.3	You are responsible for checking, confirming and satisfying yourself as to the accuracy of any Details.
                3.4	You are responsible for instructing a surveyor and/or obtaining legal advice before committing to any purchase.
                3.5	You are responsible for ensuring that you act in good faith towards any other parties.
                3.6	You represent and warrant that your use of our app will comply at all times with these Terms of Use and any further terms that may apply to you in relation to your use of our app, including all amendments and revisions to these Terms in accordance with Clause 8 herein;

4. LIMITATIONS
4.1	In no event will we be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the app, even if one of our authorized representatives has been notified orally or in writing of the possibility of such damage.
4.2	We will not be liable for any loss or damage arising under or in connection with:
Any failures due to software or internet errors or unavailability, or any other circumstances beyond our reasonable control;
any loss of your password or account if caused by a breakdown, error, loss of power or otherwise cause by or to your computer system and/or your account;
a)	the use of, or inability to use, our app;
b)	the reliance on any content or information displayed on our Website;
c)	any direct, consequential, special or punitive loss, damage, costs and expenses;
d)	loss of profit;
e)	loss of business;
f)	loss of reputation;
g)	depletion of goodwill; or
h)	Loss of, damage to or corruption of data.
4.3	Unless we otherwise expressly agree in writing, you agree not to use our app for any commercial or business purposes.
4.4	We will not be liable for any loss or damage caused by a virus, distributed denial of service attack or other technological harmful material that may infect your computer equipment, computer programs, mobile programs, data or other proprietary material due to your use of our app or to your downloading of any content on it, or any app linked to it.
4.5	If you enquire about a property on this app, you acknowledge and agree that your details will be sent by email directly to the agent, estate agent, and landlord, developer marketing the property or properties you are enquiring about. We do not accept any liability for any subsequent communications that you receive directly from that estate agent, landlord or developer and/or any third party.
5. REVISIONS AND ERRORS
The materials appearing on the Website could include technical, typographical, or photographic errors. We do not warrant that any of the materials on its app are accurate, complete, or current. We may make changes to the materials contained on the app at any time without notice.
6. AVAILABILITY OF APP
We strive to ensure that our APP and the services are available to you at all times but cannot guarantee that either the App or the services will operate continuously, without interruptions or be fault free. On occasion, necessary maintenance or upgrade work requires us to make the App and the services unavailable without notice, but we aim to keep downtime to a minimum. We accept no liability for any interruption or loss of service. We reserve the absolute right to alter, suspend or discontinue any part of our App or the services, including your access to it.
7. LINKS & THIRD PARTIES
Our App may contain links, hyperlinks and pointers to third party products, services and/or websites that are not affiliated with us. We have no control over the products, services or websites of these third parties and we do not guarantee or take responsibility for them. Our App may also contain advertising from third parties and we are not responsible, nor do we make any warranties or representations for any misleading or inaccurate advertisements which are the sole responsibility of the advertiser.
Any links or advertisements on our App should not be taken as an endorsement by us of any kind. Furthermore, our App contains data provided by third parties and we accept no responsibility, nor do we make any warranties or representations for any inaccuracies in this material. You agree to release us from any claims or disputes of any kind arising from or in any way connected to such disputes with third parties.
By using the App, you grant us an irrevocable, world-wide, royalty free license to commercialese, copy, license to other persons, use and adapt for any purpose any material you generate or submit to make use of the App.
We do not warrant that the content, links, or sub-domains contained on, or associate with our App will be available and accessible to you at all times. Information on our publications, posts, inserts, information, and content should not be regarded as a substitute for professional legal, financial or real estate advice.
8. APP TERMS OF USE MODIFICATIONS
We may revise these Terms of Use and any such Terms for the APP at any time without notice. By using this APP you are agreeing to be bound by the Terms of Use.
9. CONTRIBUTIONS
9.1	In these Terms of Use “Contributions” means any information including data, text, video, still images, audio or other material that we have permitted you to host, share, publish, post, store or upload on our App.
9.2	We may at any time, without liability to you, remove, alter or disable access to any or all of your Contributions in our sole discretion without prior notice to you. Without limiting the previous sentence, we may remove or disable access to any or all of your Contributions if we consider that:
a)	those Contributions are in breach of any law or regulation;
b)	those Contributions infringe the intellectual property rights of any third party;
c)	it is required to do so by a regulatory body or any relevant authority pursuant to an interim or final take-down notice;
d)	those Contributions;
i.	misleading or deceptive;
ii.	inappropriate having regard to the purpose of our app;
iii.	likely to cause offence;
iv.	materially incorrect;
v.	obscene;
vi.	defamatory;
vii.	otherwise unlawful and/or against the customs or norms of the region in which this App is referred too; or
viii.	Corrupted, due to the presence of a virus or other disabling code.
9.3	To the extent that any Contributions are proprietary in nature, you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable license to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display and publicly perform your Contributions throughout the world in any medium, whether currently in existence or not.
9.4	You also grant each user of our App the right to use your name or the name you submit with the Contribution, and, the right to represent and warrant that:
a)	you own and control all of the rights to the Contributions; or
b)	You have the lawful right including all necessary licenses, rights, consents and permissions to use and authorize us to display the Contributions.
9.5	For any Contributions that you may retain moral rights in, you declare that:
a)	you do not require that any personally identifying information be used in connection with the Contribution, or any derivative work, upgrade or update of the Contribution; and
b)	You understand that when accessing our App you may be exposed to the Contributions of other users of our App. You acknowledge and agree that we do not have control of and are not responsible nor do we warrant the veracity of these other Contributions.
9.6	You represent and warrant that:
a)	you have the lawful right including all necessary licenses, rights, consents and permissions to use and authorize us to display your Contributions;
b)	you will not make any Contributions that infringe the intellectual property rights of any third party, and you agree to pay all royalties, fees or other monies payable by reason of any Contributions made by you; and
c)	you will not make any Contributions that:
i.	are misleading;
ii.	are deceptive;
iii.	are materially incorrect;
iv.	are likely to cause offence;
v.	directly or indirectly involve the advertising or marketing of any products or services;
vi.	are obscene, including pornographic, hateful, racially or ethnically offensive material;
vii.	are defamatory;
viii.	are otherwise unlawful or encourage unlawful conduct; or
ix.	Are otherwise inappropriate having regard to the purpose of our App.

10. INTELLECTUAL PROPERTY
10.1	Unless otherwise expressly stated, all contents of the App are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by us, or one of our affiliates or by third parties who have licensed their materials to us and are protected by the applicable laws.
10.2	We, together with our suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on this App. Access to this App does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights. Any use of this App or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use, is prohibited without our permission. You may not modify, distribute or re-post anything on this App for any purpose.
10.3	Our names and logos and all related product and service names, design marks and slogans are the trademarks or service marks of us or our licensors. No trademark or service mark license is granted in connection with the materials contained on this App.
10.4	Access to this App does not authorize anyone to use any name, logo or mark in any manner whatsoever.





11. ELECTRONIC COMMUNICATIONS
11.1	When you use the App or send emails to us, you are communicating with us electronically. You consent to receive electronically any communications related to your use of this App. We will communicate with you by email, chat or by posting notices on this App. You agree that all agreements, notices, disclosures and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing. All notices from us intended for receipt by you shall be deemed delivered and effective when sent to the email address you provide on the App for your account.

12. INDEMNITY
12.1	You agree to indemnify and hold us and our affiliates (and our officers, agents, partners and employees) against any and all loss, liability, claim or demand, including reasonable attorney’s fees, arising out of, or in connection with your use of and access to our App or making Contributions not in accordance with the Terms.

13. DISCLAIMER
The materials on our App are provided on an "as is" and “as available” basis and we make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, we do not warrant or make any representation concerning the accuracy, likely results, or reliability of the use of the materials on the App or otherwise relating to such materials or on any site linked to this App.
14. GOVERNING LAW
14.1	These Terms of Use and your access to the App is subject to and governed by the laws of the Arab Republic of Egypt. You agree to submit to the exclusive jurisdiction of the Courts of the Arab Republic of Egypt.
14.2	If any term of the Agreement is or may become for any reason invalid or unenforceable at law, the validity and enforceability of the remainder will not be affected.
15. CONTACT US
15.1	Estategps.com subscribers are licensed real estate brokers, developers and hotel apartment providers. Our advertisers are contractually obligated to only list properties that are available for sale or lease with the proper authority from the owner and all other required governing bodies. Properties listed on estategps.com should be a fair and accurate portrayal of the property itself and the proposed transaction. To report any suspected fraudulent or misleading property postings on our App please send us an email with the details to info@estategps.com
If you have any queries, complaints or recommendations about these Terms of Use, please contact us at the following address: info@estategps.com



                </Text>
            </Stack>
            <Footer/>
        </>
      )
}

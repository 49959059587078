import React, { useState ,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    Divider ,
    Text,
    Stack,
    Center
  } from '@chakra-ui/react';
  import {FcGoogle} from "react-icons/fc";
  import {BsFacebook} from 'react-icons/bs';
  import {AiFillTwitterCircle} from "react-icons/ai"
  import { useNavigate } from 'react-router-dom';
  import { login } from '../../actions/userActions'
  import Loader from '../Loader';
  import Message from "../Messages"
const LoginForm = () => {
  const navigate  = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('')
  const [password, setpassword] = useState('')


  const userLogin = useSelector((state) => state.userLogin)
  console.log(userLogin)
  const { loading, error, userInfo } = userLogin



  const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : ''
  const currrentTokenFromStorage = userInfoFromStorage?.token
 

  const submitHandler = (e) => {
      e.preventDefault()
      dispatch(login(email,password ,currrentTokenFromStorage))
  }

  const lang = localStorage.getItem('lang')
  ? localStorage.getItem('lang')
  : 'en'

  const loginError = "There is Some error in login please try agin"
  

  return (
    <>
    { !currrentTokenFromStorage?
      <Flex pl={8} py={4} flex={1}  justify={'center'} className="register-form-reg">
      <Stack spacing={4} w={'full'} maxW={'md'}>
        <Heading fontSize={'2xl'} fontFamily="Arial" >Welcome Back</Heading>
         <Center>
             {loading && <Loader />}
         
             {error &&  
                      <>
                   
                            <Message variant='danger'>
                                    <li aria-current="true"  >{error} </li>
                            </Message>
                   
                    </> 
                }              
         </Center>
        <form onSubmit={submitHandler} style={{backgroundColor:'white'}}>
          <FormControl id="email" >
            <Input type="email" placeholder='email'   
              onChange={(e) => setEmail(e.target.value)}
              required
              className='mb-2 input-reg'
              bg={"gray.100"}
              />
          </FormControl>
          <FormControl id="password" >
            <Input type="password" 
                  placeholder='password'
                  className='input-reg mb-2'  
                  onChange={(e) => setpassword(e.target.value)}
                  required 
                  bg={"gray.100"}
                  />
               <div bg={"white.100"} className="mb-3" style={{cursor:'pointer'}} onClick={()=>{navigate("/ForgetPassword")}}>
                 forgetPassword ?
              </div>

          </FormControl>
        <Stack spacing={6}>
          <Button bg="#006AFF" colorScheme={'blue'} variant={'solid'} type="submit" >
          Login
          </Button>
          <Stack
            direction={"row"}
            align={'center'}
            >
              <Divider color={"#464646"} />
              <Text fontFamily={"Arial"} fontSize="20px" color={"#464646"} >
                OR
              </Text>
              <Divider color={"#464646"} />
          </Stack>
          <Stack
            direction={"row"}
            align={'center'}
            justify="center"
            spacing={6}
            >
              <AiFillTwitterCircle className='twi-reg-icon'/>
              <FcGoogle className='google-reg-icon'/>
              <BsFacebook className='face-reg-icon'/>
          </Stack>
          <Stack
            direction={"row"}
            align={'center'}
            justify="start"
            spacing={0}
            >
              <Text mr={2}>
              have an account ? 
              </Text>
              <Button onClick={()=>{navigate('/Register')}} className='login-word-reg'>
                Register
              </Button>
          </Stack>
        </Stack>
        </form>
      </Stack>
    </Flex>: navigate("/")
    }
    </>
  )
}

export default LoginForm
import React, {useState , useEffect} from 'react'
import {MdLocationOn} from 'react-icons/md'
import {BsHeartFill} from 'react-icons/bs'
import {AiFillStar} from 'react-icons/ai'
import Loader from '../../Loader'
import Message from '../../Messages'
import { useNavigate } from 'react-router'
import {
  Center
} from '@chakra-ui/react';

export default function Companies() {
  
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [companies, setcompanies] = useState();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Apipassword", "mimic2022");
    myHeaders.append("lang", "ar");

    var formdata = new FormData();
    formdata.append("search", "");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://estategps.com/admin/guest/getAllCompanies", requestOptions)
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(false);
        setcompanies(result);
      },
      (error) => {
        setIsLoaded(false);
        setError(error);
      }
    )


  }, [])
  return (
    <div className='container'>
       <>{
            isLoaded&& <div className='my-4'><Center><Loader/></Center></div>
          }
        </>
           {error &&  <Message variant='danger'>
                        {
                        error.message?
                        <Message>
                              {error.message}
                            </Message>:
                            <>
                            {Object.values( error.errors).map(err=>(
                              <>
                              {
                                err.map(e=>(
                                  <ul >
                                    <li aria-current="true">{e} </li>
                                  </ul>
                                ))
                              }
                              </>
                            ))}
                            </> 
                      }
             </Message>}
           { companies && <>
                    {
                       companies?.companies.map((company , index)=>{
                      
                        return  <div className='row' key={index} style={{cursor:'pointer'}}  onClick={()=>{navigate(`/Company/${company?.id}`)}}>
                                    <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'98%'}}>
                                      <div className=" mobile-direction desktop-direction g-0" >
                                        <div className='mobile-direction-img desktop-direction-img' style={{overflow:'hidden'}} >
                                          <img src={company.image} className="rounded"  style={{ objectFit:'cover',height:'260px' , width:'320px'}} alt="..."/>
                                        </div>
                                        <div className='mobile-direction-content desktop-direction-content '>
                                          <div className="content-direction">
                                            <div  className="d-flex justify-content-between card-title fs-5">
                                              <h5>{company.name}</h5>
                                              <BsHeartFill className='me-2' style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'red' }} />
                                            </div>
                                            <div className="d-flex card-text align-items-center">
                                                            <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px' }} />
                                                            <h1 className='fs-6'>{company.city}, {company.address}</h1>
                                            </div>
                                            <div className='d-flex card-text align-items-center mt-1'>
                                                <p style={{color:'#006AFF'}}>$240 \Mnth</p>
                                            </div>
                                            <div className="d-flex card-text align-items-center mt-2">
                                                            <h1 className='fs-6 me-2 '>3.5</h1>
                                                            <AiFillStar  style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'#F9CA24' }} />
                                            </div>
                                            <div className="d-flex card-text align-items-center justify-content-between mt-3">
                                                      <div className='d-flex align-items-center justify-content-between'>
                                                            <img  src={require('../../../Images/distance.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                              <h1 className='fs-6  '>3.5</h1>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <img src={require('../../../Images/lamp.svg').default} style={{width:'19px' , height:'19px'}} className="me-2" alt="..."/>
                                                              <h1 className='fs-6  '>2</h1>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <img src={require('../../../Images/bed.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                              <h1 className='fs-6  '>4</h1>
                                                        </div>
                                                            
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                        
                       })
                    }
           
              </>

           }  
          
    </div>
  )
}

import React from 'react'
import {
    Stack,
    Flex,
    Container,
    Heading,
    Box,
    VStack,
    Text,
    } from '@chakra-ui/react';
import Footer from "../../components/Footer/Footer"
import NavBar from "../../components/Header/Navbar"

export default function About() {
  return (
    <>
        <NavBar/>
        <Flex 
            height={'60vh'} 
            backgroundColor="#DCE5E4"
            overflow={'hidden'}
            >
              <Flex color='white'  
                    width={'full'}
                    marginTop={'50'}
                    h={'50vh'} height={'63vh'}  
                    direction={{ base: 'column-reverse', md: 'row' }} 
                   
                    >
                    <Box flex={'1'}  height={'full'} >
                    <Flex
                            className='easymove'
                            w={'50wh'}
                            h={'full'}
                            z-zIndex={'-2'}
                            justifyContent={'center'}
                            backgroundImage={
                            require('../../Images/about.svg').default
                            }
                        
                            backgroundRepeat={'no-repeat'}
                            backgroundSize={'cover'}
                            backgroundPosition={'center center'}>
                                
                    </Flex>
                    </Box>
                    <Box  flex={'1'}>
                    <Flex
                        className='easymove'
                        w={'50wh'}
                        height={'full'}
                        justifyContent={'center'}
                    >
                            
                                <VStack align={'center'} justifyContent={'center'} alignItems={'center'}>
                                    <Text  color={'black'} fontSize={'30'} fontWeight={'bold'} marginBottom={'4'}>Find Your Place</Text>
                                    <Text color={'black'} fontSize={'22'}>Here The Buyer Meets The Seller</Text>
                                    <Text color={'black'} fontWeight={'bold'} fontSize={'25'}>Join Us</Text>
                                </VStack>
                        
                            
                        </Flex>
                </Box>
                </Flex>
        </Flex>
        <Stack spacing={4} mt={5} mb={5} as={Container} maxW={'5xl'} textAlign={'center'}>
            <Heading 
                bg={""}
                fontSize={'4xl'}
                fontFamily={"'Poppins', sans-serif !important"}
                fontWeight="500"
            >
                About
            </Heading>
            <Text color={'#151617'} fontSize={'xl'}fontFamily={"'Poppins', sans-serif !important"} >
            It is a long established fact that a reader will be  Ipsum as their default model text
            It is a long established fact that a reader will be  Ipsum as their default model text
            It is a long established fact that a reader will be  Ipsum as their default model text

            </Text>
        </Stack>
        <Footer/>
    </>
  )
}

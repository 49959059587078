import React from 'react'
import "./CustomBtnShape.css"

export default function CustomBtnShape({BtnName , backgroundColor , textColor}) {
  return (
    <>
         <button  className='d-flex rounded justify-content-center p-6 m-2 align-items-center btn-style SmScreenFontSize LgScreenFontSize' style={{backgroundColor:backgroundColor , color:textColor , fontWeight:'normal' , height:'50px', width:'20%' ,marginRight:'5px'}}>
           {BtnName}
         </button>
    </>
  )
}

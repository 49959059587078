import React from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({ variant, children }) => {
  return <Alert variant={variant}style={{fontSize:"15px",fontWeight:"700",fontFamily:"sans-serif" }} >{children}</Alert>
}

Message.defaultProps = {
  variant: 'info',
}

export default Message

import axios from "axios";
import {
	USER_REGISTER_SUCCESS,
	USER_REGISTER_REQUEST,
	USER_REGISTER_FAIL,
	USER_LOADED,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	USER_AUTH_ERROR
} from "../constants/userConstants";
// import setAuthToken from "../utils/setAuthToken";
const StateURL = 'http://estategps.com/admin';

// Load User
export const loadUser = () => async (dispatch) => {
	// if (localStorage.token) {
	// 	setAuthToken(localStorage.token);
	// }

	try {
		const res = await axios.get("http://localhost:3000/users/auth");

		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: USER_AUTH_ERROR,
		});
	}
};

// Register User
export const register =
	(userName,email,password,passwordConfirmation,gender,countery,city,phone,image ) =>
 	async (dispatch) => {
		try {
						var FormData = require('form-data');
						var Userdata = new FormData();

						Userdata.append('user_name',userName);
						Userdata.append('email', email);
						Userdata.append('password', password);
						Userdata.append('password_confirmation', passwordConfirmation);
						Userdata.append('country_id', countery);
						Userdata.append('city_id', city);
						Userdata.append('phone', phone);
						Userdata.append('gender', gender);
						Userdata.append('image', image);

						var config = {
						method: 'post',
						url:StateURL+ '/client/register',
						headers: { 
							'Apipassword': 'mimic2022', 
							'lang': 'en', 
						},
						mimeType: "multipart/form-data",
						data : Userdata
						};

						 const {data} = await axios(config)
						if(data.status){
							localStorage.setItem('userEmail', JSON.stringify(email))
							dispatch({type: USER_REGISTER_SUCCESS})
							document.location.href='/Verification'

						}else{
							dispatch({type: USER_REGISTER_FAIL,payload:data});

						}
						 
					
		} catch (error) {
			dispatch({
				type: USER_REGISTER_FAIL,
				payload: error?.response ? error?.response?.data:  "not handeld error Try Again"
			});
		}
	};


// Logout / Clear Profile

export const logout = () => (dispatch) => {
	dispatch({ type: USER_LOGOUT });
};

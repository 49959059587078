import React from 'react'
import {MdLocationOn} from 'react-icons/md'
import {BsHeartFill} from 'react-icons/bs'
import {AiFillStar} from 'react-icons/ai'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Listing.css"

export default function Listing() {


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='my-3 '>
    <div><h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'17px'}}>Company Listing</h1></div>
       <div className='row'>
           
         <Slider {...settings}>
           <div style={{width:'98%' , height:'90%'}}>
                     <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'98%'}}>
                        <div className="mobile-direction desktop-direction g-0" >
                          <div className='mobile-direction-img desktop-direction-img' >
                            <img src={require('../../../Images/duplexAppart.svg').default} className="rounded"  style={{ objectFit:'cover',height:'100%' , width:'100%'}} alt="..."/>
                          </div>
                          <div className='mobile-direction-content desktop-direction-content '>
                            <div className="content-direction">
                              <div  className="d-flex justify-content-between card-title fs-5">
                                <h5>Duplex Appartment</h5>
                                <BsHeartFill className='me-2' style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'red' }} />
                              </div>
                              <div className="d-flex card-text align-items-center">
                                              <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px' }} />
                                              <h1 className='fs-6'>5th settelement, New Cairo</h1>
                              </div>
                              <div className='d-flex card-text align-items-center mt-1'>
                                  <p style={{color:'#006AFF'}}>$240 \Mnth</p>
                              </div>
                              <div className="d-flex card-text align-items-center mt-2">
                                              <h1 className='fs-6 me-2 '>3.5</h1>
                                              <AiFillStar  style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'#F9CA24' }} />
                              </div>
                              <div className="d-flex card-text align-items-center justify-content-between mt-3">
                                        <div className='d-flex align-items-center justify-content-between'>
                                              <img  src={require('../../../Images/distance.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>3.5</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/lamp.svg').default} style={{width:'19px' , height:'19px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>2</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/bed.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>4</h1>
                                          </div>
                                              
                              </div>
                            </div>
                          </div>
                     </div>
                </div>
            </div>
            <div style={{width:'98%' , height:'90%'}}>
                     <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'98%'}}>
                        <div className="mobile-direction desktop-direction g-0" >
                          <div className='mobile-direction-img desktop-direction-img' >
                            <img src={require('../../../Images/duplexAppart.svg').default} className="rounded"  style={{ objectFit:'cover',height:'100%' , width:'100%'}} alt="..."/>
                          </div>
                          <div className='mobile-direction-content desktop-direction-content '>
                            <div className="content-direction">
                              <div  className="d-flex justify-content-between card-title fs-5">
                                <h5>Duplex Appartment</h5>
                                <BsHeartFill className='me-2' style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'red' }} />
                              </div>
                              <div className="d-flex card-text align-items-center">
                                              <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px' }} />
                                              <h1 className='fs-6'>5th settelement, New Cairo</h1>
                              </div>
                              <div className='d-flex card-text align-items-center mt-1'>
                                  <p style={{color:'#006AFF'}}>$240 \Mnth</p>
                              </div>
                              <div className="d-flex card-text align-items-center mt-2">
                                              <h1 className='fs-6 me-2 '>3.5</h1>
                                              <AiFillStar  style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'#F9CA24' }} />
                              </div>
                              <div className="d-flex card-text align-items-center justify-content-between mt-3">
                                        <div className='d-flex align-items-center justify-content-between'>
                                              <img  src={require('../../../Images/distance.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>3.5</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/lamp.svg').default} style={{width:'19px' , height:'19px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>2</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/bed.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>4</h1>
                                          </div>
                                              
                              </div>
                            </div>
                          </div>
                     </div>
                </div>
            </div>
            <div style={{width:'98%' , height:'90%'}}>
                     <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'98%'}}>
                        <div className="mobile-direction desktop-direction g-0" >
                          <div className='mobile-direction-img desktop-direction-img' >
                            <img src={require('../../../Images/duplexAppart.svg').default} className="rounded"  style={{ objectFit:'cover',height:'100%' , width:'100%'}} alt="..."/>
                          </div>
                          <div className='mobile-direction-content desktop-direction-content '>
                            <div className="content-direction">
                              <div  className="d-flex justify-content-between card-title fs-5">
                                <h5>Duplex Appartment</h5>
                                <BsHeartFill className='me-2' style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'red' }} />
                              </div>
                              <div className="d-flex card-text align-items-center">
                                              <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px' }} />
                                              <h1 className='fs-6'>5th settelement, New Cairo</h1>
                              </div>
                              <div className='d-flex card-text align-items-center mt-1'>
                                  <p style={{color:'#006AFF'}}>$240 \Mnth</p>
                              </div>
                              <div className="d-flex card-text align-items-center mt-2">
                                              <h1 className='fs-6 me-2 '>3.5</h1>
                                              <AiFillStar  style={{marginRight:'2px' , width:'20px',height:'22px' ,color:'#F9CA24' }} />
                              </div>
                              <div className="d-flex card-text align-items-center justify-content-between mt-3">
                                        <div className='d-flex align-items-center justify-content-between'>
                                              <img  src={require('../../../Images/distance.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>3.5</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/lamp.svg').default} style={{width:'19px' , height:'19px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>2</h1>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-between'>
                                              <img src={require('../../../Images/bed.svg').default} style={{width:'25px' , height:'25px'}} className="me-2" alt="..."/>
                                                <h1 className='fs-6  '>4</h1>
                                          </div>
                                              
                              </div>
                            </div>
                          </div>
                     </div>
                </div>
            </div>
        
         </Slider>
   </div>    
    </div>
  )
}

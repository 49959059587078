
import axios from "axios"




const StateURL = 'https://estategps.com/admin';


 

// get companies by id 


export const SubmittForm =async (token , name , email , phone , problem)=>{
      const  myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer  ${token}` );
      myHeaders.append("Apipassword", "mimic2022");
      myHeaders.append("lang", "ar");

      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("phone", phone);
      formdata.append("problem", problem);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${StateURL}/client/Message/sendMessageToService`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}



export const getBanners = async ()=>{

    var FormData = require('form-data');
    var Userdata = new FormData();
    Userdata.append('search', '');

    var config = {
      method: 'get',
      url: StateURL+'/guest/getBanners',
      headers: { 
        'Apipassword': 'mimic2022', 
        'lang': 'ar', 
      },
      data : Userdata
    };

   const {data:{banners}} = await axios(config)
   console.log("banners" + banners)
    return banners;

}
export const getCompaniesById = async (id) =>{

              
            var FormData = require('form-data');
            var Userdata = new FormData();
            Userdata.append('id', `${id}`);

            var config = {
              method: 'post',
              url: '/guest/getAllCompaniesSearch',
                headers: { 
                'Apipassword': 'mimic2022', 
                'lang': 'ar', 
              },
              data : Userdata
            };

            //  const {data} = await axios.get(config);
            //   console.log(data)
          
          axios(config)
          .then(function (response) {
            console.log(response.data.companies)
          })
          .catch(function (error) {
            console.log(error);
          });
            

}
 
// get All Countries
export const getAllCountries =async () =>{


        var config = {
          method: 'get',
          url: StateURL+'/guest/getAllCountries',
          headers: { 
            'Apipassword': 'mimic2022', 
            'lang': 'en', 
          },
        };
    
    const {data:{countries}} = await axios.get(config);
        console.log("countries" + countries)
       return countries;

}

// getting all unites 
export const  getAllunits= async ()=>{

     const Bearer = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3JveWFsbWF6YWQuY29tL3B1YmxpYy9lc3RhdGVfZ3BzL3B1YmxpYy9jbGllbnQvbG9naW4iLCJpYXQiOjE2NTYzMzk1MjQsIm5iZiI6MTY1NjMzOTUyNCwianRpIjoiTWtPOTZWVWUxVWpQekx6biIsInN1YiI6NiwicHJ2IjoiNDFlZmI3YmFkN2Y2ZjYzMmUyNDA1YmQzYTc5M2I4YTZiZGVjNjc3NyIsInR5cGUiOiJjbGllbnQifQ.lw8kopQzMT0o-mNYjle0cBm3sbtiLvxu6q4by98SGPs' 
    const config = {
        headers: {
          "Apipassword" : "mimic2022",
          "lang":"ar"
        },
      }
     const {data:{units:{data}}} = await axios.post(StateURL +"/guest/getAllUnit",(Bearer),config);
     return data;
     
}


import React ,{useEffect , useState} from 'react'
import Slider from 'react-slick'
import Navbar from '../../components/Header/Navbar'
import UnitSlider from '../../components/UnitSlider/UnitSlider'
import Description from '../../components/CompanyDetails/CompanyDescription/CompanyDescription' 
import CallLinks from '../../components/CompanyDetails/CallLinks/CallLinks'
import Branches from '../../components/CompanyDetails/Branches/Branches'
import Projects from '../../components/CompanyDetails/Projects/Projects'
import Listing from '../../components/CompanyDetails/Listing/Listing'
import Reviews from '../../components/CompanyDetails/Reviews/Reviews'
import WriteReview from '../../components/CompanyDetails/WriteReview/WriteReview'
import {Route, Link, Routes, useParams} from 'react-router-dom';

import {getCompaniesById} from "../../api/index"



export default function CompanyDetails() {


  const [companyData , setCompanyData]  =  useState([])

  const params = useParams();
  
  useEffect(()=>{
  //  setCompanyData(getCompaniesById(params.id))
  },[])

  console.log(companyData)

  return (


    <>
    <Navbar/>
    <div className='container'  style={{overflow:'hidden'}}>
        <div className='text-center' style={{fontSize:'25px'}}>Company Details</div>
        <UnitSlider/>
        {/* Comapnay Description */}
        {
          companyData &&   
           <div className='row   rounded  mb-4'>
              <div className='col'>
                <div className='row'>
                  <div className='col'>
                      <dl class="row">

                          <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Metro</dt>
                          <dd class="col-sm-9"><p>img elements must have an alt prop, either with meaningful text, or an empty string for decorative imagesimg elements must have an alt prop, either with meaningful text, or an empty string for decorative imagesimg elements must have an alt prop, either with meaningful text, or an empty string for decorative images</p></dd>

                          <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Company Type</dt>
                          <dd class="col-sm-9">
                              <p>150 m</p>
                          </dd>
                          <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Email</dt>
                          <dd class="col-sm-9">
                              <p>500 m</p>
                          </dd>
                          <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Mobile</dt>
                          <dd class="col-sm-9">
                              <p>200 m</p>
                          </dd>
                          <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Registiration number</dt>
                          <dd class="col-sm-9">
                              <p>No</p>
                          </dd>

                      </dl>       
                  </div>
                </div>
              </div>
          </div>
        }

        {/* End Of COmpany Description  */}
        {/* call Links */}
        <CallLinks/>
        {/* Branches */}
        <Branches/>
        {/* company Projects */}
        <Projects/>
        {/* Company Listing */}
        <Listing/>
        {/* Reviews */}
        <Reviews/>
        {/* Write A review */}
        <WriteReview/>
    </div>
    </>
  )
}

import React from 'react'
import CustomBtn from '../Buttons/CustomBtnShape'
import { Divider, AspectRatio , SkeletonCircle, Skeleton, SkeletonText , Box  } 
from '@chakra-ui/react'
import "./UnitDescription.css"
import {MdLocationOn} from 'react-icons/md'
import {GiPathDistance} from 'react-icons/gi'
import {MdBed} from 'react-icons/md'
import {GiDeskLamp} from 'react-icons/gi'
import {BiSend} from 'react-icons/bi'
import DescriptionList from '../DescriptionList/DescriptionList'
import Card from '../Card/Card'

export default function fUnitDescription() {
  return (
    <div className='container mt-2 ' style={{overflow:'clip'}}>
        {/* Villa and Price */}
        <div className='row mb-2  d-flex justify-content-between'>
                <div className='col'>
                        <h1 style={{fontWeight:'bold' ,fontSize:'25px'}}>Villa in the North Coast</h1>
                </div>
                <div className='col align-items-start'>
                    <div className='row'>
                            <div  className='col'>
                                    <div className='row'>
                                    <div className='d-flex align-items-center'>
                                            <MdBed style={{marginRight:'4px' , width:'20px',height:'22px' }} />
                                            <h1>4</h1>
                                        </div>
                                    </div>
                            </div>
                            <div  className='col'>
                                    <div className='row'>
                                    <div className='d-flex align-items-center'>
                                            <GiDeskLamp style={{marginRight:'4px' , width:'20px',height:'22px' }} />
                                            <h1>2</h1>
                                        </div>
                                    </div>
                            </div>
                            <div  className='col'>
                                    <div className='row'>
                                    <div className='d-flex align-items-center'>
                                            <GiPathDistance style={{marginRight:'4px' , width:'20px',height:'22px' }} />
                                            <h1>2</h1>
                                        </div>
                                    </div>
                            </div>
                    </div>
                </div>
        </div>
        <div className='row mb-2'>
                <h1 style={{fontSize:'18px' , fontWeight:'bold'}}>12,000,000 EGP</h1>
        </div>
        {/* end of Villa and Price */}
        {/*Logo And Location */}
        <div className='row d-flex justify-content-between align-items-center  mt-4 '>
                <div className='col-8'>
                        <div className='d-flex align-items-center'>
                            <MdLocationOn style={{marginRight:'2px' , width:'20px',height:'22px' , color:'red'}} />
                            <h1>Cairo, Egypt</h1>
                        </div>
                </div>
                <div className='col-3'>
                        {/* There Is An Icon Need To Change */}
                    <button className='btn btn-primary'> <i className='fa fa-map-marker me-2'></i> Show On Map</button>
                </div>
        </div>
        {/*End Logo And Location */}
        {/* Buttons */}
           <div className='row mt-4'>
                <CustomBtn BtnName={'Status'} backgroundColor={'#006AFF'} textColor={'white'}/>                           
                <CustomBtn BtnName={'Description'} backgroundColor={'#dbd3d3'} textColor={'black'}/>                           
                <CustomBtn BtnName={'Details'}  backgroundColor={'#dbd3d3'} textColor={'black'}/>                           
                <CustomBtn BtnName={'Description'}  backgroundColor={'#dbd3d3'} textColor={'black'}/>                           
           </div>
           <div className='row'>    

                <div className='col-3 '>

                        <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                        <li className='mx-2 my-3'style={{color:"#0D6EFD"}} >Description</li>
                        <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                        <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                        <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                </div>
                
                <div className='col-3 '>

                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3'style={{color:"#0D6EFD"}} >Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                </div>

                <div className='col-3'>

                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3'style={{color:"#0D6EFD"}} >Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                </div>

                <div className='col-3 '>

                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3'style={{color:"#0D6EFD"}} >Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                <li className='mx-2 my-3' style={{color:"#0D6EFD"}}>Description</li>
                </div>

               
           </div>
        {/* end of Buttons */}
        <div className='row'>
                <div style={{lineHeight:'1.3' ,overflow:'hidden' , height:"90px"}}>  
                    survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently 
                    with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum,
                    survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently 
                    with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
        </div>
        {/* About Developer */}
        <div className='row mt-4 mb-2'>
                <div className='col'>
                    <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'27px'}}>About Developer</h1>
                    <div className='rounded p-4 d-flex justify-content-start align-items-center' style={{border:'1px solid #006AFF'}}>
                        <div className='d-flex'>
                            <div >
                                <div className='rounded '>
                                     {/* Need To be Changed */}
                                    <img className='rounded-circle' src={require('../../../src/Images/Avatar.png' )} style={{width:'70px' , height:'70px' , objectFit:'cover'}}/>
                                </div>
                            </div>
                            <div className='ms-4'>
                                    <div className='col'>
                                        <h1 style={{fontSize:'1.4rem' ,  fontWeight:'bold'}}>Real estate Company</h1>              
                                        <div className='d-flex align-items-center my-2'>
                                            <MdLocationOn style={{marginRight:'2px' , width:'20px',height:'22px' , color:'red'}} />
                                            <h1>Cairo, Egypt</h1>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        {/* End Of About Developer */}
        {/* Other Data */}
        <div className='mt-4'>
                <div>
                    <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'23px'}}>Other Data</h1>
                </div>
                
                <div className='row border rounded p-3 mb-4'>
                    <div className='col'>
                    <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'15px'}}>Distances</h1>            
                       <div className='row'>
                       <div className='col'>
                            <dl class="row">

                                <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Metro</dt>
                                <dd class="col-sm-9">10 m</dd>

                                <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Train</dt>
                                <dd class="col-sm-9">
                                    <p>150 m</p>
                                </dd>
                                <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Bus</dt>
                                <dd class="col-sm-9">
                                    <p>500 m</p>
                                </dd>
                                <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Pharmacy</dt>
                                <dd class="col-sm-9">
                                    <p>200 m</p>
                                </dd>
                                <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Beach</dt>
                                <dd class="col-sm-9">
                                    <p>No</p>
                                </dd>

                            </dl>       
                        </div>
                        <div className='col-1 align-items-center mt-2'>
                                
                            <Divider orientation='vertical' style={{width:'2px' , opacity:'0.2', height:'100%'}} />
                        
                        </div>
                        <div className='col'>
                          <dl class="row">
                            <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Metro</dt>
                            <dd class="col-sm-9">10 m</dd>

                            <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Train</dt>
                            <dd class="col-sm-9">
                                <p>150 m</p>
                            </dd>
                            <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Bus</dt>
                            <dd class="col-sm-9">
                                <p>500 m</p>
                            </dd>
                            <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Pharmacy</dt>
                            <dd class="col-sm-9">
                                <p>200 m</p>
                            </dd>
                            <dt class="col-sm-3" style={{color:'#0D6EFD' ,fontWeight:'normal', fontSize:'18px'}}>Beach</dt>
                            <dd class="col-sm-9">
                                <p>No</p>
                            </dd>
                           </dl>
                        </div>
                       </div>
                    </div>
                </div>
        </div>
        {/* end Of Other Data */}

        {/* Video */}
        <div className='mb-4'>
            <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'20px'}}>Video</h1>
              <AspectRatio maxW='560px' maxH='400px' ratio={1}>
                <iframe
                    title='naruto'
                    src='https://www.youtube.com/embed/QhBnZ6NPOY0'
                    allowFullScreen
                />
              </AspectRatio>
        </div>
        {/* End Of Video */}
        {/* reviews */}
        <div className='col'>
            <h1 className='mb-4' style={{fontWeight:'bold' , fontSize:'20px'}}>Reviews</h1>
                <div className='row d-flex mb-2 px-3' >
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        
                        
                </div>
        </div>
        {/* Message */}
        <div className='col mb-4 mt-4 p-2'>
            <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'20px'}}>Write A Review</h1>
            <div className='d-flex'>
                <input className='form-control me-2'/>
                <BiSend className='rounded-circle p-2' style={{color:'white' , fontSize:'40px', backgroundColor:'blue'}}/>
            </div>
        </div>
        {/* End of Message */}
    </div>
  )
}

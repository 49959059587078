import React from 'react'
import {
    Stack,
    Flex,
    Container,
    Heading,
    Box,
    VStack,
    Text,
    } from '@chakra-ui/react';
import Footer from "../../components/Footer/Footer"
import NavBar from "../../components/Header/Navbar"
import Form from "../../components/Form/Form"
import './Contactus.css'
export default function Contact() {
  return (
    <>
       <NavBar/>
        <Flex 
            height={'80vh'} 
            backgroundColor="#DCE5E4"
            overflow={'hidden'}
            >
              <Flex color='white'  
                    width={'full'}
                    h={'60vh'} height={'63vh'}  
                    direction={{ base: 'column-reverse', md: 'row' }} 
                    >
                    <Box flex={'1'}  height={'full'} >
                    <Flex
                            className='easymove'
                            w={'50wh'}
                            h={'full'}
                            justifyContent={'center'}
                            backgroundImage={
                            require('../../Images/contact.svg').default
                            }
                        
                            backgroundRepeat={'no-repeat'}
                            backgroundSize={'cover'}
                            backgroundPosition={'center center'}>
                                
                    </Flex>
                    </Box>
                    <Box  flex={'1'}>
                    <Flex
                        w={'50wh'}
                        height={'full'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={'7px'}
                    >
                            
                                <VStack  align={'center'} justifyContent={'center'} alignItems={'center'}  direction={{ base: 'column', md: 'row' }} >
                                    <Text letterSpacing={'7px'} color={'black'} fontSize={'30'} fontWeight={'bold'} marginBottom={'4'}>Connect With Us</Text>
                                    <Text  color={'black'} fontSize={'22'}>Write Any Proplem And We Will Find The Solution For You</Text>
                                </VStack>
                            
                    </Flex>
                </Box>
           </Flex>
        </Flex>
                   
            <Box width={'full'} justifyContent={'center'} alignItems={'center'} height={'50vh'}>
            <div className='customize-place'>   
                  <Form />
            </div>
                                 
            </Box>
        
        <Footer/>
    </>
  )
}

import React from 'react'
import { Divider, AspectRatio , SkeletonCircle, Skeleton, SkeletonText , Box  } 
from '@chakra-ui/react'

export default function Reviews() {
  return (
    <div>
       {/* reviews */}
       <div className='col'>
            <h1 className='mb-4' style={{fontWeight:'bold' , fontSize:'20px'}}>Reviews</h1>
                <div className='row d-flex mb-2 px-3' >
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        <div className='col-3'>
                            <Box padding='6' boxShadow='lg' bg='white'>
                                <SkeletonCircle size='10' />
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>
                        </div>
                        
                        
                </div>
        </div>
    </div>
  )
}

import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
const Loader = () => {
  return (

    <>
       <Spinner animation="border" variant="primary" />
    </>
    
    )
}

export default Loader

import React from 'react'
import {
    Flex,
    VStack,
    useBreakpointValue,
   
} from '@chakra-ui/react';
export default function Head() {
  return (
    <>
     <Flex
                
                className='easymove'
                w={'full'}
                h={'50vh'}
                backgroundImage={
                    require('../../../Images/pic4.jpg')
                }
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
    
      <VStack
        w={'full'}
        justify={'center'}
        align={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
        >
           
      </VStack>
     </Flex>
    </>
  )
}

import React from 'react'
import style from "styled-components"
import GoogleMapReact from 'google-map-react'

export default function map({setcoordinates , setbounds , coordinates ,places , setclickedChiled}) {


return (

                  <GoogleMapReact 
                  bootstrapURLKeys={{ key:'AIzaSyBGt73J3rPZKSYxHebpWeRqr2UxRFzzHPI' }}
                  defaultCenter={coordinates}
                  center={coordinates}
                  defaultZoom={14}
                  margin={[50, 50, 50, 50]}
                  options={{ disableDefaultUI: true, zoomControl: true,  }}
                  onChange={(e)=>{
                                  setcoordinates({lat: e.center.lat, lng: e.center.lng }) 
                                  setbounds({ne:e.marginBounds.ne, sw:e.marginBounds.sw})
                                }}
                  onChildClick={(child) => setclickedChiled(child)}
                >

                        { places &&
                          places.map((place , index)=>(

                              <ImgContainer 
                                lat={Number(place.latitude)}
                                lng={Number(place.longitude)}
                              >
                                      {<>
                                        <PlaceName>{place?.name}</PlaceName>
                                        <Cardimg src={place?.photo?.images?.small?.url}/>
                                      </>
                                      }
                              </ImgContainer>

                          ))
                        }
                      
                </GoogleMapReact>
     
  )
}


const Wrapper = style.div`

  flex:3;
  height:93.4vh;
  overflow:hidden;  

`

const PlaceDetails = style.div`


`
const ImgContainer = style.div`

    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:white;
    height:100px;
    width:100px;
    padding:10px;
    overflow:hidden;
    border:1px solid #ddd;
    border-radius:5px;
    &:hover{
      transform: scale(1.5);
      font-weigth:50px;
     
    }

`
const Cardimg = style.img`

  height:90%;
  width:100%;
  border:1px solid #ddd;
  border-radius:5px;
  object-fit: fill;
 

`

const PlaceName  = style.div`

  position:relative;
  color:Black;
  z-index:2;
  width:100%;
  margin-bottom:5px;
`
import React from 'react'
import {MdLocationOn} from 'react-icons/md'
import {BsHeartFill} from 'react-icons/bs'
import {AiFillStar} from 'react-icons/ai'
import {BiAlarm} from "react-icons/bi"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Branches.css"



export default function Branches() {
    
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='my-3' >
          <div className='d-flex justify-content-between' >
                <div>
                   <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'17px'}}>Branches</h1>
                </div>
                <div>
                    <h1 className='fs-4'>2 Branches</h1>
                </div>  
          </div>
          <div className='mt-3 mb-1'><h1 style={{fontWeight:'bold' , fontSize:'17px'}}>Branches</h1></div>
          <Slider  {...settings}>
              <div style={{width:'100%' , height:'90%'}}>
                  <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'100%'}}>
                                    <div className="row g-0" >
                                        <div className="col-md-1">
                                          <img src={require('../../../Images/duplexAppart.svg').default}  className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                      <div className="col-md-6">
                                        <div className="card-body">
                                          <h5 className="card-title fs-4">New Cairo Branch</h5>
                                          <p className="card-text">
                                                  <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px',color:'red' ,display:'inline' }} />
                                                    <h1 classNameName='fs-6' style={{display:'inline'}} >5th settelement, New Cairo</h1>
                                          </p>
                                          <p className="card-text mt-2">
                                                <BiAlarm  style={{marginRight:'4px' , width:'20px',height:'22px', display:'inline' }} />
                                                <h1 classNameName='fs-6' style={{display:'inline'}}>9 Am To 12 Pm</h1> 
                                          </p>
                                          <p className='card-text mt-2' style={{backgroundColor:'#DEDEDE'}}>
                                              <img className='me-2' src={require('../../../Images/mapIcon.svg').default} style={{width:'50px' , height:'50px' ,display:'inline' }} classNameName="me-2" alt="..."/>
                                              <h1 classNameName='fs-6  me-2' style={{display:'inline'}}>Go To Location</h1>
                                          </p>

                                        </div>
                                      </div>
                                    </div>
                  </div>
              </div>
              <div style={{width:'100%'}}>
                  <div className=" card mb-3 mt-2 p-2"  style={{ height:'100%' , width:'100%'}}>
                                    <div className="row g-0" >
                                      <div className="col-md-1">
                                        <img src={require('../../../Images/duplexAppart.svg').default}  className="img-fluid rounded-start" alt="..."/>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card-body">
                                          <h5 className="card-title fs-4">New Cairo Branch</h5>
                                          <p className="card-text">
                                                  <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px',color:'red' ,display:'inline' }} />
                                                    <h1 classNameName='fs-6' style={{display:'inline'}} >5th settelement, New Cairo</h1>
                                          </p>
                                          <p className="card-text mt-2">
                                                <BiAlarm  style={{marginRight:'4px' , width:'20px',height:'22px', display:'inline' }} />
                                                <h1 classNameName='fs-6' style={{display:'inline'}}>9 Am To 12 Pm</h1> 
                                          </p>
                                          <p className='card-text mt-2' style={{backgroundColor:'#DEDEDE'}}>
                                              <img className='me-2' src={require('../../../Images/mapIcon.svg').default} style={{width:'50px' , height:'50px' ,display:'inline' }} classNameName="me-2" alt="..."/>
                                              <h1 classNameName='fs-6  me-2' style={{display:'inline'}}>Go To Location</h1>
                                          </p>

                                        </div>
                                      </div>
                                    </div>
                  </div>
              </div>

              
          </Slider>
    </div>
  )
}

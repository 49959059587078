import React from 'react'
import Navbar from '../../components/Header/Navbar'
import UnitDescription from '../../components/UnitDescription/UnitDescription'
import Slider from '../../components/UnitSlider/UnitSlider'

export default function unitDetails({id}) {
  return (
    <div style={{overflow:'hidden'}}>
        <Navbar/>  
          <div className='row mt-3'>
              <div className="position-relative ">
                  <div className="position-absolute top-0  top-0 start-50 translate-middle"><h1 className='center' style={{fontSize:'25px'}}>Unit Details</h1></div>
              </div>
          </div>
        <Slider/>
        <UnitDescription/>
    </div>
  )
}


import React from 'react'
import {
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    Stack,
  } from '@chakra-ui/react';
  
const ResetForm = () => {
  return (
    <Flex pl={8} py={4} flex={1}  justify={'center'} className="register-form-reg">
      <Stack spacing={4} w={'full'} maxW={'md'}>
        <Heading fontSize={'2xl'} fontFamily="Arial" >Reset Password</Heading>
        <FormControl id="Old Password" bg={"gray.100"}>
          <Input type="password" placeholder='Old Password'className='input-reg'  />
        </FormControl>
        <FormControl id=" New Password" bg={"gray.100"}>
          <Input type="password" placeholder=' New Password'  className='input-reg' />
        </FormControl>
        <Stack >
          <Button bg="#006AFF" colorScheme={'blue'} variant={'solid'}>
            Reset Password          
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default ResetForm
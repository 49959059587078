import React from 'react'
import {
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    Stack,
  } from '@chakra-ui/react';
  import { useNavigate } from 'react-router-dom';
  

const ForgetForm = () => {
  const navigate  = useNavigate();

  return (
    <Flex pl={8} py={4} flex={1} pt={12}   justify={'center'} className="register-form-reg">
      <Stack spacing={4} w={'full'} maxW={'md'}>
        <Heading fontSize={'2xl'} fontFamily="Arial" >Forget password</Heading>
        <FormControl id="email" bg={"gray.100"}>
          <Input type="email" placeholder='email'  className='input-reg' />
        </FormControl>
        <Stack spacing={6} >
          <Button bg="#006AFF" colorScheme={'blue'} variant={'solid'} onClick={()=>{navigate("/Verification")}}>
              Send
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default ForgetForm
import React, { createRef } from 'react'
import { useState , useEffect } from "react";
import ProductAddToCart from '../Home/Product/Product'
import { getAllunits } from '../../api';
import Card from "../Card/Card"
import PlaceDetails from "../PlaceDetails/PlaceDetails";
import styled from 'styled-components'
import Footer from "../Footer/Footer"
export default function List() {
    

  return (

       <div>
          <div style={{scrollBehavior:'smooth'}}>
              <div className='d-flex mobile-direction-cls justify-content-around'>
                  <div  >
                      <Card id={5}/>
                      </div>
                    <div >
                      <Card id={6}/>
                    </div>    
              </div>
              <div className='d-flex mobile-direction-cls justify-content-around'>
                    <div  >
                        <Card id={5}/>
                    </div>
                    <div >
                      <Card id={6}/>
                    </div>    
              </div>    
              <div className='d-flex mobile-direction-cls justify-content-around'>
                    <div  >
                        <Card id={5}/>
                    </div>
                    <div >
                      <Card id={6}/>
                    </div>    
              </div>    
           </div>
           
              <Footer/>
                                  
     </div>

)
;

}
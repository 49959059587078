import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store'
import { ChakraProvider,extendTheme } from '@chakra-ui/react';
const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const theme = extendTheme({ colors })
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
        <App />
    </Provider>
  </ChakraProvider>,
  document.getElementById('root')
);


reportWebVitals();

import React from 'react'

import {MdLocationOn} from 'react-icons/md'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import "./Projects.css"
export default function Projects() {

  const settings = {
    classNameName: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow:1,
    speed: 500,
  
  };
    return (
       
      <div>
          
          {/* <Slider {...settings} >
                <div >  
                <div className="card" style={{width: "18rem;",height:'100%'}}>
                  <img src={require('../../../Images/duplexAppart.svg').default} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title fs-4">Villa in The North Coast</h5>
                        <p className="card-text">
                            <MdLocationOn  style={{marginRight:'2px' , width:'20px',height:'22px' , color:'red' , display:'inline'}} />
                            <h1 className='.font-resize' style={{display:'inline'}}>5th settelement, New Cairo</h1>
                        </p>
                    </div>
                </div>
              
                </div>
            
        </Slider> */}

      </div>

  )
}

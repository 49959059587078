import React from 'react'
import {
  Flex,
  Stack,
  Image,
} from '@chakra-ui/react';
import "./register.css"
import Form from '../../components/Register/Form';

const Register = () => {
  return (
    <div className="container mt-5 mb-2">
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Form/>
        <Flex flex={1} pr={8} display={{ base: 'none', md: 'flex' }} >
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            borderRadius="10px"
            src={
              require('../../Images/register.png')
            }
          />
        </Flex>
    </Stack>
  </div>
  )
}


export default Register
import {React ,useState , useEffect} from 'react'
import ProductAddToCart from '../Home/Product/Product'
import './ListAndMap.css'
import Footer from "../../components/Footer/Footer"
import List from '../List/List'
import Map from "../map/map"
import { Center } from '@chakra-ui/react'
import {getPlacesData} from "../../api/index"
import {getAllunits} from "../../api/index"

export default function ListAndMap() {


  const [clickedChiled , setclickedChiled]  = useState([])
  const [places , setPlaces]  = useState([])
  const [coordinates , setcoordinates]  = useState({})
  const [bounds , setbounds]  = useState([])


    useEffect(()=>{
          navigator.geolocation.getCurrentPosition(({coords:{latitude ,longitude}})=>{
            setcoordinates({lat:latitude,lng:longitude})

          })

    },[])

  // useEffect(()=>{
  //   getPlacesData(bounds.sw,bounds.ne).then((data)=>{
  //     setPlaces(data);
  //     })
  // },[coordinates,bounds])



   

 
  return (

            <div style={{overflowY:'hidden', overflowX:'hidden'}}  className="container pb-3" >
                
                                    <div className='mobile-reorganize-cls d-flex justify-content-between mt-4'>
                                      <div className='mobile-reorganize-width-cls col-6' >
                                        <input placeholder='Discover !'  class="form-control"/>
                                      
                                      </div>
                                      <div className='mobile-reorganize-width-cls col-6  ' >
                                          <div className='d-flex justify-content-between align-items-center'>
                                            <button className=' btn-resize font-resize ' ><i class="fa fa-circle" style={{color:'red', fontSize:'10px'}}></i> For Rent</button>
                                            <button className=' btn-resize font-resize' >Price</button>
                                            <button className=' btn-resize font-resize choosen-toHide ' >Beds & Bashes</button>
                                            <button className=' btn-resize font-resize' >More</button>
                                          </div>
                                      </div>
                                    </div>
                
                                 {/* Map And List  */}
                                  <div className='mobile-reorganize-cls d-flex justify-content-between mt-2' >
                                      <div className='map col-6' style={{height:'80vh'}}>
                                          <Map
                                            setcoordinates={setcoordinates}
                                            setbounds={setbounds}
                                            coordinates={coordinates}
                                            places={places}
                                            setclickedChiled =  {setclickedChiled}
                                          />
                                          <Map/>
                                       </div>
                                       <div className='mobile-reorganize-width-cls col-6' style={ {boxSizing:'content-box' ,height:'80vh' , overflowX:'hidden',} }>
                                            <div className='row align-items-center justify-content-center'>
                                                <div className='col bg-white  rounded py-4 px-4'>
                                                      <div className='row mb-4'>
                                                        <Center>
                                                                <h1 style={{fontSize:'30px'}}>Nabraska Real Estate & Homes For Sale </h1>
                                                        </Center>
                                                      </div>
                                                    <div className='row justify-content-between  mb-4 align-items-center px-4 SortBtns '>
                                                            <div className='col-sm-12 col-md-8'>
                                                                <div className='kfokvl' style={{width:'100%'}}>
                                                                        <div className='category-option'>
                                                                                    <button  type="button" className ="  option left-option active-option resizeTxt"> <div >1213</div> Agent Listing</button>
                                                                                    <button type="button"   className =" option right-option  resizeTxt"><div >4312</div> Other Listing</button>
                                                                        </div>    
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-0 col-md-4 hideSelectBox' >
                                                                <select  class="form-select" aria-label="Default select example">
                                                                    <option value="0">Sort By</option>
                                                                    <option value="1">Home</option>
                                                                    <option value="2">Price</option>
                                                                    <option value="3">Lot</option>
                                                                </select>
                                                            </div>
                                                    </div>
                                                    <div className='row'>
                                                        <List 
                                                          placesDetails={places}
                                                          clickedChiled = {clickedChiled}
                                                        />
                                                    </div>
                                                  
                                              </div>
                                            </div>
                                        </div>
                                  </div>
            </div>
       
  )
}

import React from 'react'
import {BiSend} from 'react-icons/bi'

export default function WriteReview() {
  return (
    <div>
       <div className='col mb-4 mt-4 p-2'>
            <h1 className='mb-3' style={{fontWeight:'bold' , fontSize:'20px'}}>Write A Review</h1>
            <div className='d-flex'>
                <input className='form-control me-2'/>
                <BiSend className='rounded-circle p-2' style={{color:'white' , fontSize:'40px', backgroundColor:'blue'}}/>
            </div>
        </div>
    </div>
  )
}

import { Flex , 
Box,
Input,
Select,
Center,
Image,
InputGroup,
InputLeftElement,
Container,
VStack,
Heading,
useBreakpointValue,
SimpleGrid,
Text,
Button
} from '@chakra-ui/react'
import Service from '../../components/Home/Service/Service';
import ProductAddToCart from '../../components/Home/Product/Product';
import React , {useEffect , useState} from 'react'
import Navbar from '../../components/Header/Navbar'
import { AiOutlineSearch } from 'react-icons/ai';
import {ImLocation} from "react-icons/im"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Home.css"
import { getAllunits  , getBanners } from '../../api';
import Loader from "../../components/Loader"
import Footer from '../../components/Footer/Footer';

export default function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [loading , setLoading] = useState(false);
  const [units , setUnits] = useState();
  const [coordinates , setcoordinates]  = useState({})
  const [banners , setBanners]  = useState()


  // get Current User Location
  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(({coords:{latitude ,longitude}})=>{
      setcoordinates({lat:latitude,lng:longitude})
    })
},[])


// get All units
  useEffect(()=>{
      setLoading(true)
      getAllunits().then((data)=>{
        setUnits(data)
      })
      setLoading(false)
  },[units])

  console.log(units)

// get All Banners
  useEffect(()=>{
    setLoading(true)
    getBanners().then((data)=>{
      setBanners(data)
    })
    setLoading(false)
  },[banners])

  console.log(banners)


  return (
    <div style={{overflowY:'hidden'}}>
      {/* navBar */}
        <Navbar/>
      {/* Slider */}
      <div  style={{height:'600px' , backgroundColor:'#F1F2F2'}} className='d-flex flex-column justify-content-around pt-4 '>
          <div  className='d-flex justify-content-end pt-4 pe-4'>
              <Image  src={require('../../Images/Clouds.svg').default} className="img-fluid"/>
          </div>
          <div className='mobile-direction-cls container d-flex justify-content-center w-75 mb-4 ' >
                <div className='input-cls' >
                    <Box>
                        <InputGroup >
                              <InputLeftElement
                                pointerEvents='none'
                                children={<AiOutlineSearch color='black' />}
                              />
                        <Input className='me-2' type='tel' placeholder=' Citry Or Town'  maxW={"700px"} bg={"white"}/>
                        </InputGroup>
                    </Box>
                </div>
              <div className='btn-group-cls d-flex '>
                 <div className='me-2'>
                      <Select placeholder='Property Type' bg={"white"}>
                          <option value='option1'>Option 1</option>
                          <option value='option2'>Option 2</option>
                          <option value='option3'>Option 3</option>
                        </Select>
                  </div>
                  <div  className='me-2' >
                      <Select placeholder='Price' bg={"white"}>
                          <option value='option1'>Option 1</option>
                          <option value='option2'>Option 2</option>
                          <option value='option3'>Option 3</option>
                        </Select>
                  </div>
                  <div  className='me-2'>
                      <Select placeholder='Area (M)' bg={"white"}>
                          <option value='option1'>Option 1</option>
                          <option value='option2'>Option 2</option>
                          <option value='option3'>Option 3</option>
                        </Select>
                  </div>
                  <div  >
                      <Button bg={"#006AFF"} color={"white"} className='px-4'>Search</Button>
                  </div>
              </div>
          </div>
          <div className='homes-mobile-cls d-flex justify-content-start mt-4'>
             <Image src={require('../../Images/Group 42559.svg').default} className="img-fluid"/>
          </div>
      </div>
      <div style={{backgroundColor:'#006AFF' , height:'120px' , overflow:'hidden'}} className="d-flex w-100 justify-content-between ">
          <div className='d-flex justify-content-between w-25'>
            <Image  src={require('../../Images/CollectionDots.svg').default} style={{marginLeft:'-50px'}} />
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'30px' , height:'30px'}} className="mt-3"/>
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'50px' , height:'50px'}} className="align-self-center"/>
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'30px' , height:'30px'}} />
          </div>
          <div className='d-flex align-items-center justify-content-center flex-direction-column'>
              <div className='text-center'>
                  <p style={{color:"white"}} className=" fs-2">
                    100,000,000 <span>Unit</span>
                 </p>
                   <h1 style={{color:'white'}}>Total Number Of Units</h1>

              </div>
          </div>
          <div className='d-flex justify-content-between w-25'>
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'30px' , height:'30px'}} className="mt-3"/>
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'50px' , height:'50px'}} className="align-self-center"/>
            <Image  src={require('../../Images/MeduimBubble.svg').default} style={{width:'30px' , height:'30px'}} />
            <Image  src={require('../../Images/CollectionDots.svg').default} style={{marginRight:'-50px'}} />
          </div>
      </div>
      {/* How It Works */}
      <div  style={{height:'600px' , backgroundColor:"#F1F2F2"}}>
          <div className='container'> 
              <div className='d-flex flex-column align-items-center  pt-4'>
                <div className='d-flex align-items-center'>
                    <Image  src={require('../../Images/bagy.svg').default} className="img-fluid me-2"/>
                    <p className='fs-3 fw-bold'>How It Works</p>
                </div>
                <div className='text-center'>
                   <p className='font-size-mobile font-size-desk'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p>
                </div>
              </div>
              
              <div className='d-flex flex-column align-items-center  pt-4'>
                      <div className='text-center'>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active nav-link" id="Company-tab" data-bs-toggle="tab" data-bs-target="#Company" type="button" role="tab" aria-controls="Company" aria-selected="true">Company </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="User-tab" data-bs-toggle="tab" data-bs-target="#User" type="button" role="tab" aria-controls="User" aria-selected="false">User</button>
                      </li>
                    </ul>
                      </div>
                    <div className="tab-content " id="myTabContent">
                      <div className="tab-pane fade show active" id="Company" role="tabpanel" aria-labelledby="Company-tab">
                          <div className='d-flex flex-column align-items-center pt-4' style={{width:'100%' , height:'100%'}}>
                           <div className='text-center w-100'>
                              <div className='mx-4 my-2'>
                                         <h1 className='font-size-mobile font-size-desk'>As a client you can do the Following</h1>
                                </div>
                                <div className='text-center w-100 '> 
                                  <div className='f-width-mobile  d-flex justify-content-around align-items-center mt-2  px-3'>
                                    <Image  src={require('../../Images/Note.svg').default} style={{width:'90px' , height:'90px'}} className=" custum-mobile-icons"/>
                                      <p className='font-size-mobile-select font-size-desk-select'>Select Client Segment</p>
                                    <Image  src={require('../../Images/sheet.svg').default} style={{width:'90px' , height:'90px'}} className="custum-mobile-icons"/>
                                  </div>
                                </div>
                           </div>
                            <div className='mx-4 text-center'>
                              <p className='font-size-mobile font-size-desk'>You Can Create New Order By Filling the following Data</p>
                            </div>
                            <div className='text-center w-75 mt-4 '> 
                                  <div className='f-width-mobile  d-flex  align-items-center mt-2  px-3'>
                                      <div className='d-flex align-items-center w-50 '>
                                        <div className='d-flex justify-content-center rounded-circle align-items-center me-2 dots-cls'  > 1 </div>
                                        <div className='me-2' style={{width:'90%',height:'8px', backgroundColor:'#d3d3d3'}}></div>
                                        
                                      </div>
                                      <div className='d-flex align-items-center w-50'>
                                        <div className='me-2 d-flex justify-content-center rounded-circle align-items-center dots-cls' > 2 </div>
                                        <div className='me-1' style={{width:'90%',height:'8px', backgroundColor:'#d3d3d3'}}></div>
                                        <div className='d-flex justify-content-center rounded-circle align-items-center dots-cls'  > 3 </div>
                                      </div>
                                        </div>
                                </div>
                            <div className='text-center w-100 mt-4 px-3'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex flex-column w-25 text-center '>
                                        <p className='font-size-mobile-select font-size-desk-select'>Add New Unit</p>
                                        <p className='font-size-mobile font-size-desk'>
                                          Lore is an American horror anthology television series developed.
                                        </p>
                                    </div>
                                    <div className='w-50 d-flex justify-content-center align-items-center ms-3'>
                                    <Image  src={require('../../Images/sheet.svg').default} style={{width:'90px' , height:'90px'}} className="custum-mobile-icons"/>
                                   </div>
                                   <div className='d-flex flex-column w-25 text-center '>
                                        <p className='font-size-mobile-select font-size-desk-select'>Preview Adds Result</p>
                                        <p className='font-size-mobile font-size-desk'>
                                          Lore is an American horror anthology television series developed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                          </div>


                      </div>
                     <div className="tab-pane fade" id="User" role="tabpanel" aria-labelledby="User-tab">User</div>
                    </div>
              </div>
              
          </div>
      </div>
      {/* Services */}
      <div className=" mt-5" style={{backgroundColor:'#006AFF'}}>
              <Box py={4}>
                <Center>
                  <Heading
                      fontSize={'3xl'}
                      fontFamily={"'Poppins', sans-serif !important"}
                      fontWeight="500"
                      className='shadow-head'
                      color={"white"}
                    >
                    Select Your Services
                  </Heading>
                </Center>
                  <Box className='container'>
                      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                        <Service destination={'Buy'}  image={'service1.svg'} text="Lorem dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero" title={"Buy Homes"} />
                        <Service image={'service2.svg'} text="Lorem dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero" title={"Sell A Home"} />
                        <Service image={'service3.svg'} text="Lorem dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero" title={"Rent A Home"} />
                      </SimpleGrid>
                  </Box>
              </Box>
            
        </div>
      {/*Sale [Slider ]  */}
       <div className='container mt-4'>
       <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                                <Flex 
                                        className="d-block w-100"
                                        w={'full'}
                                        h={'75vh'}
                                        mb={5}
                                        backgroundImage={
                                        require('../../Images/Sale.svg').default
                                        }
                                        backgroundSize={'cover'}
                                        backgroundPosition={'center center'}
                                        backgroundRepeat={'no-repeat'}
                                        >
                                            <div className="d-flex px-5 justify-content-start align-items-center ">
                                            <div>
                                              <Text color={"white"} fontFamily="Arial" fontSize={"50px"} fontWeight="900" >
                                                sale
                                                </Text>
                                                <Text color={"white"} fontFamily="Arial" fontSize={"50px"}  fontWeight="900">
                                                  50% off
                                                </Text>
                                            </div>
                                                                </div>
                                                              
                                    </Flex>
               
              </div>
              <div className="carousel-item">
                                <Flex 
                                        className="d-block w-100"
                                        w={'full'}
                                        h={'75vh'}
                                        mb={5}
                                        backgroundImage={
                                        require('../../Images/BG.svg').default
                                        }
                                        backgroundSize={'cover'}
                                        backgroundPosition={'center center'}
                                        backgroundRepeat={'no-repeat'}
                                        >
                                            <div className="d-flex px-5 justify-content-start align-items-center ">
                                            <div>
                                              <Text color={"white"} fontFamily="Arial" fontSize={"50px"} fontWeight="900" >
                                                sale
                                                </Text>
                                                <Text color={"white"} fontFamily="Arial" fontSize={"50px"}  fontWeight="900">
                                                  50% off
                                                </Text>
                                            </div>
                                                                </div>
                                                              
                                    </Flex>
               
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
       </div>
       {/* Popular Near You */}
       {loading ? <Loader/> :
             <div className="container mt-5">
              <Box py={4}>
                <Center>
                  <Heading
                      fontSize={'3xl'}
                      fontFamily={"'Poppins', sans-serif !important"}
                      fontWeight="500"
                    >
                     {units && "Popular Near You"}
                      
                    
                  </Heading>
                </Center>
                
                    <div className='container row d-flex'>
                      <Slider {...settings}>
                            {
                              loading ? <Loader/> : units?.map((unit , index)=>{
                                return <ProductAddToCart key={index} 
                                                  id={unit.id} 
                                                  name= {unit.title}
                                                  cityArea={unit.city_area} 
                                                  city={unit.city} 
                                                  price={unit.price}
                                                  images={unit.images}
                                                />
                              })
                            }
                            </Slider>
                    </div>
                
              </Box>
            </div>
      }
        {/* Footer */}
        <Footer/>
    
    </div>
  )
}

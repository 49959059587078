import React,{useState,useEffect } from 'react'
import {
    Button,
    Image,
    Flex,
    FormControl,
    Heading,
    Input,
    Divider ,
    Text,
    Stack,
    Select,
    Center
  } from '@chakra-ui/react';
  import {FcGoogle} from "react-icons/fc";
  import {BsFacebook} from 'react-icons/bs';
  import {AiFillTwitterCircle} from "react-icons/ai"
  import { useNavigate } from 'react-router-dom';
  import {register} from "../../actions/auth"
  import { useDispatch,useSelector } from 'react-redux'
  import Message from '../Messages' 
  import Loader from '../Loader'
  import {getAllCountries} from "../../api/index"


const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error} = userRegister
  

  useEffect(()=>{

  },[])

  const [userName, setUsername] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()
  const [gender, setGender] = useState()
  const [countery, setCountery] = useState(3)
  const [city, setCity] = useState()
  const [phone, setPhone] = useState()
  const [image, setImage] = useState(null)
  const [countries , setCountries] = useState();
  



    useEffect(()=>{ 
      getAllCountries().then((countries)=>{
        setCountries(countries)
      })

      
    },[countery,gender,city])

   
  
  const submitHandler = (e) => {
    e?.preventDefault()
     dispatch(register(userName,email,password,passwordConfirmation,gender,countery,city,phone,image))
}


  return (
      <>
   
          <Flex pl={8} py={4} pt={8} flex={1}   justify={'center'} className="register-form-reg">
          <Stack spacing={4} w={'full'} maxW={'md'}>
            <Heading fontSize={'2xl'} fontFamily="Arial" >Welcome to our website </Heading>

                  <Center>
                    {loading && <Loader />}
                  </Center>
                {error &&  
                      <>
                      {Object?.values( error?.message)?.map(err=>(
                        <>
                        {
                          err?.map((e , index)=>(
                            <Message variant='danger' key={index}>
                                    <li aria-current="true"  >{e} </li>
                            </Message>
                            
                          ))
                        }
                        </>
                      ))}

                    </> 
                }              
            <form onSubmit={(e)=>{submitHandler(e)}} style={{backgroundColor:'white'}}>
                <FormControl id="UserName" bg={"gray.100"} className="mb-2">
                  <Input type="text" placeholder='User Name' value={userName}  onChange={(e) => setUsername(e.target.value)} className='input-reg' />
                </FormControl>
                <FormControl id="email" bg={"gray.100"}  className="mb-2">
                  <Input type="email" placeholder='email' value={email}  onChange={(e) => setEmail(e.target.value)}  className='input-reg' />
                </FormControl>
                <FormControl id="password" bg={"gray.100"}  className="mb-2">
                  <Input type="password" placeholder='password' value={password}  onChange={(e) => setPassword(e.target.value)} className='input-reg'  />
                </FormControl>
                  <FormControl id="confirm_password" bg={"gray.100"}  className="mb-2">
                    <Input type="password" placeholder='Confirm password' value={passwordConfirmation}  onChange={(e) => setPasswordConfirmation(e.target.value)} className='input-reg'  />
                  </FormControl>
                <FormControl id="pic" bg={"gray.100"}  className="mb-2">
                  <Input
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      setImage(event.target.files[0]);
                    }}
                  />
                </FormControl>
              
                    {image && (
                        <FormControl   className='mb-2' >
                        <div className='d-flex align-items-center'>
                          <Image alt=".."  style={{width:"100px" , height:'70px'}} src={URL.createObjectURL(image)}  className="rounded mb-1 me-2"/>
                        <br />
                        <button className=" btn-danger p-2 rounded" style={{height:'40px'}} onClick={()=>setImage(null)} >Remove</button>
                        </div>
                        </FormControl>
                      )}
          
                <FormControl id="Gender" bg={"gray.100"}  className="mb-2">
                  <Select placeholder='Gender' value={gender}  onChange={(e) => setGender(e.target.value)} >
                      <option className='form-control' value='0'>Male</option>
                      <option value='1'>Female</option>
                    </Select>
                </FormControl>
                <FormControl id="Country" bg={"gray.100"}  className="mb-2">                      
                    <Select placeholder='Countery' value={countery}   onChange={(e) => setCountery(e.target.value)}>
                       {countries &&
                        (
                          countries?.map((element,index)=>{
                             return <option key={index} value={element?.id}>{element?.name}</option>
                            })
                        )
                       }
                      </Select>
               
                </FormControl>
                <FormControl id="City" bg={"gray.100"}  className="mb-2">
                  <Select placeholder='City' onChange={(e) => setCity(e.target.value)} >
                       {countries &&
                        (
                          countries?.map((element,index)=>{
                          return  (
                              element.cities.map((city)=>{
                                return <option  value={city?.id}>{city.name}</option>
                              
                             })
                            )
                          }))
                        
                       }
                      </Select>
                </FormControl>
                <FormControl id="Phone number" bg={"gray.100"}  className="mb-2">
                  <Input type="tel" placeholder='Phone number' value={phone}  onChange={(e) => setPhone(e.target.value)}  className='input-reg'  />
                </FormControl>
            </form>
            
            <Stack spacing={6}>
              
              <Button onClick={()=>{submitHandler()}} bg="#006AFF" colorScheme={'blue'} variant={'solid'}>
                Register
              </Button>
              <Stack
                direction={"row"}
                align={'center'}
                >
                  <Divider color={"#464646"} />
                  <Text fontFamily={"Arial"} fontSize="20px" color={"#464646"} >
                    OR
                  </Text>
                  <Divider color={"#464646"} />
              </Stack>
              <Stack
                direction={"row"}
                align={'center'}
                justify="center"
                spacing={6}
                >
                  <AiFillTwitterCircle className='twi-reg-icon'/>
                  <FcGoogle className='google-reg-icon'/>
                  <BsFacebook className='face-reg-icon'/>
              </Stack>
              <Stack
                direction={"row"}
                align={'center'}
                justify="start"
                spacing={2}
                >
                  <Text>
                    have an account ? 
                  </Text>
                  <Button onClick={()=>{navigate('/Login')}} className='login-word-reg'>
                    Login
                  </Button>
              </Stack>
            </Stack>
          </Stack>
         </Flex>
         
      </>
  )
}

export default Form
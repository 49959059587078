import {React , useEffect} from 'react'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {Image  } 
from '@chakra-ui/react'
export default function UnitSlider({UnitData}) {

    const Data = {
        Images:['duplexAppart.svg','pic2.jpg','duplexAppart.svg','pic1.jpg','duplexAppart.svg']
    }


    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "10px",
        speed: 500 ,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.3,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 3,
              infinite: false,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (

        <div className='row text-center' >
                    <Carousel {...settings} className='mt-4 mb-4' style={{overflow:'hidden'}}>
                    {
                         Data?.Images.map((element , i)=>{
                            return (
                                <Wrap key={i}  > 
                                  <img src={require(`../../Images/${element}`)} style={{height:"300px",width:'97%' , objectFit:'cover'}}/> 
                               </Wrap> )  
                        })
                    }
               </Carousel>
        </div>
  )
}



const Carousel=  styled(Slider)`

 `

const Wrap = styled.div`
img{
    border:4px solid transparent;
    width:100%;
    height:100%;
    transition-duration:500ms;
    &:hover{
        border:4px solid rgb(13, 110, 253) ;
        border-radius:4px;
    }

`
import React from 'react'
import {
  Flex,
  Stack,
  Image,
} from '@chakra-ui/react';
import ResetForm from '../../components/ResetPassword/ResetForm';
const ResetPassword = () => {
  return (
    <div className="container mt-5">
      <Stack minH={'50vh'} direction={{ base: 'column', md: 'row' }}>
      <ResetForm/>
      <Flex flex={1} pr={8} display={{ base: 'none', md: 'flex' }} >
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          borderRadius="10px"
          src={
            require('../../Images/register.png')
          }
        />
      </Flex>
    </Stack>
  </div>
  )
}

export default ResetPassword
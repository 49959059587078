import React from 'react'

import {
    Flex,
    Stack,
    Image,
  } from '@chakra-ui/react';
import VerificationForm from "../../components/VerificationForm/VerificationForm"

export default function Verification() {
  return (
    <div className="container mt-5">
    <Stack minH={'50vh'} direction={{ base: 'column', md: 'row' }}>
        <VerificationForm/>
        <Flex flex={1} pr={8}  display={{ base: 'none', md: 'flex' }} >
            <Image
              alt={'Login Image'}
              objectFit={'cover'}
              borderRadius="10px"
              src={
                require('../../Images/register.png')
              }
            />
        </Flex>
    </Stack>
    </div>
  )
}

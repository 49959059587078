import React from 'react'
import {BiMessageRounded} from 'react-icons/bi'

export default function CallLinks() {
  return (

    <div className='d-flex justify-content-center  align-items-center'>
            <div className='d-flex me-4'> <img src={require('../../../Images/messageIcon.svg').default} style={{width:'40px' ,height:'40px'}}/></div>
            <div className='d-flex me-4'> <img src={require('../../../Images/whatsappIcon.svg').default} style={{width:'40px' ,height:'40px'}}/></div>
            <div className='d-flex '> <img src={require('../../../Images/phoneIcon.svg').default} style={{width:'40px' ,height:'40px'}}/></div>
            
    </div>
  )
}

import React from 'react'
import styled  from 'styled-components'

export default function PlaceDetails({place , selected , refProp}) {

    if(selected) refProp?.current?.scrollIntoView({behavior:"smooth" , block:"start"})


  return (
    <Wrapper>
         <Card>
           <Cardimg src= {place?.photo ? (place?.photo?.images.large.url) : 'https://www.vectorstock.com/royalty-free-vector/hotel-consierge-working-avatar-character-vector-21070175' }/>
           <h4>{place?.name}</h4>
             <PriceSec>
                <PriceTxt>Price</PriceTxt>
                <PriceValue>{place?.price ? place?.price : "Unknown" }</PriceValue>
            </PriceSec>
            <RatingSec>
                <RatingTxt>Rating</RatingTxt>
                <RatingValue>{place?.rating ? place?.rating : "Unknown" }</RatingValue>
            </RatingSec>
            <PhoneSec>
                <PhoneTxt>Phone</PhoneTxt>
                <PhoneValue>{place?.phone ? place?.phone : "Unknown" }</PhoneValue>
            </PhoneSec>
            <WebSec>
                <WebLink href={place?.web_url ? place?.web_url : "" } onClick={()=>{window.open(place?.web_url,'_blank')}} >Web Site</WebLink>
            </WebSec>
            <LocationSec>
                <LocationAdds>{place?.address}</LocationAdds>
            </LocationSec>
         </Card>
       
    </Wrapper>
  )
}

const Wrapper = styled.div`

    margin-top:10px;
    height:100%;

`

const Card = styled.div`

  display:flex;
  flex-direction:column;
  box-sizing: border-box;
  height:100%;
  overflow:hidden;
  border:1px solid #ddd;
  border-radius:5px;
  margin-right:15px;
  padding:10px;
  margin-bottom:15px;
  h4{
    margin-left:5px;
    text
  }
`

const PriceSec = styled.div`

  display:flex;
  justify-content:space-between;



`
const RatingSec = styled.div`

      display:flex;
      justify-content:space-between;

`
const RatingTxt = styled.div` 

`
const RatingValue = styled.div`

`
const PhoneSec = styled.div`
display:flex;
justify-content:space-between;


`

const PhoneTxt = styled.div``
const PhoneValue = styled.div``

const WebSec =styled.div`
  display:flex;
  justify-content:space-between;

`

const WebTxt = styled.div`
`

const WebLink = styled.div`

  &:hover{
    cursor:pointer;
    color:blue;
  }

`

const PriceTxt  = styled.div`

`

const PriceValue = styled.div`

`


const Cardimg  = styled.img`

    height:50%;
    width:100%;
    background-size:cover;

`

const LocationSec = styled.div`


`

const LocationAdds = styled.div`


`
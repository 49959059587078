import React from 'react'
import ListAndMap from '../../components/ListAndMap/ListAndMap'
import Navbar from '../../components/Header/Navbar'
import'./Buy.css'
import {
      Box,
  
      } from '@chakra-ui/react';

export default function CompaniesMap() {
  return (
   
         
          <>
             <Navbar background={'#DCE5E4'}/>
              <Box  background={'#DCE5E4'} >   
                <ListAndMap/>
              </Box>
           </>
  )
}

import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'

import {
  userContactReducer,
  userLoginReducer,
  userLogoutReducer,
  userRegisterReducer
} from './reducers/userReducers'

export const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister:userRegisterReducer,
    userLogout:userLogoutReducer,
    userContact:userContactReducer,
   
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : ''

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
import React,{useState ,setState } from 'react'
import Head from '../../components/Home/Head/Head'
import './filiter.css'
import styled from 'styled-components'
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react'
const Filiter = (Places) => {

    const [ShowBar , setShowBar] = useState(false)

  return (
    <React.Fragment>
               <Head/>
                <FilterBox ShowBar={ShowBar}>
                    <HeadersTitle>
                        <FilterTxtx>Filter</FilterTxtx>
                        <FilterTxtx>Clear All</FilterTxtx>
                    </HeadersTitle>
                    <ActionsBtns>
                        <SellBtn>Sell</SellBtn>
                        <RentBtn>Rent</RentBtn>
                    </ActionsBtns>
                    <OptionsSection>
                        <PropertyType>
                            <ul>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                            </ul>
                        </PropertyType>
                        <RequiredField>
                            <ul>
                            <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                            </ul>
                        </RequiredField>
                    </OptionsSection>
                    <OptionsSection>
                        <RangeBar>
                            <RangeSlider aria-label={['min', 'max']} defaultValue={[10, 30]}>
                                    <RangeSliderTrack>
                                        <RangeSliderFilledTrack />
                                    </RangeSliderTrack>
                                    <RangeSliderThumb index={0} />
                                    <RangeSliderThumb index={1} />
                            </RangeSlider>
                        </RangeBar>
                        <FinishesAndPaints>
                            <ul>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                                <li>Any</li>
                                <li>Appartment</li>
                                <li>Office</li>
                                <li>Appartment</li>
                            </ul>
                        </FinishesAndPaints>
                    </OptionsSection>
                    <ResultBtn >Show 200 Result</ResultBtn>
                </FilterBox>
        
    </React.Fragment>
  )
}

export default Filiter

const FilterBox = styled.div`


    position: absolute;
    top: calc(100vh - 450px);;
    left:15%;
    right:10%;
    display:flex;
    flex-direction:column;
    align-items:center;
    width:70%;
    background-color:white;
    border-radius:5px;
    padding-top:15px;
    padding-left:50px;
    padding-right:50px;
    // transition:transform 0.7s ease-in-out;
    z-index:2;
    display:${props => props.ShowBar === true ? '': 'none' };

    @media(max-width:768px){

        align-items:center;
       top: calc(100vh - 450px);
        left:10%;
        right:10%;
      
      }
    
    
     



`

const HeadersTitle = styled.div`

    width:100%;
    display:flex;
    justify-content:space-between;
    @media(max-width:768px){
        display:flex;
        flex-direction:column;
        align-items:center;
      
      }
    @media(max-width:920px){
        display:flex;
        flex-direction:column;
        align-items:center;
      
      }
`

const FilterTxtx  = styled.h1`
    font-size:30px;
    @media(max-width:768px){
        font-size:15px;

      
      }
      @media(max-width:920px){
        font-size:15px;

      
      }
`

const ActionsBtns = styled.div`

    display:flex;
    justify-content:center;
    margin-top:15px;
    width:100%;
    @media(max-width:768px){

        flex-direction:column;
        align-items:center;
      
      }
      @media(max-width:920px){

        flex-direction:column;
        align-items:center;
      
      }
`
const SellBtn = styled.button`

    width:52%;
    height:50px;
    background-color:#006AFF;
    border-radius:5px;
    font-size:30px;
    margin-right:-10px;
    @media(max-width:768px){

        margin-right:0px;
        margin-bottom:2px;
        font-size:15px;
    
          
          
      
      }
      @media(max-width:920px){

        margin-right:0px;
        margin-bottom:2px;
        font-size:15px;
    
          
          
      
      }

`

const RentBtn = styled.button`

    width:50%;
    height:50px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color:white;
    border:1px solid #006AFF;
    font-size:30px;
    @media(max-width:768px){
        font-size:15px;
      }
      @media(max-width:920px){
        font-size:15px;
      }

`

const OptionsSection = styled.div`

    display:flex;
    justify-content:space-between;
    width:100%;
    margin-top:20px;
    @media(max-width:768px){
        display:flex;
        flex-direction:column;
        align-items:center;
      }
      @media(max-width:920px){
        display:flex;
        flex-direction:column;
        align-items:center;
      }


`

const PropertyType  = styled.div`

    display:flex;
    justify-content:space-around;
    align-items:center;
    width:45%;
    border:1px solid black;
    border-radius:5px;
      
    @media(max-width:768px){
        display:flex;
        flex-direction:column;
        align-items:center;
        width:100%;
        margin-bottom:5px;
      }
        
      @media(max-width:919px){
        display:flex;
        flex-direction:column;
        align-items:center;
        width:100%;
        margin-bottom:5px;
      }

    ul{
        list-style:none;
        display:flex;
            overflow: scroll;
            overflow-y: hidden;
        ::-webkit-scrollbar {
            width: 0;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
        /* Optional: show position indicator in red */
        ::-webkit-scrollbar-thumb {
            background: #FF0000;
        }
        overflow:hidden;
       
        @media(max-width:768px){
            display:flex;
            flex-direction:column;
            align-items:center;
          }
          @media(max-width:919px){
            display:flex;
            flex-direction:column;
            align-items:center;
          }
          


    
        li{
            margin:10px;
            box-sizing: border-box;
            padding:6px;
           
            }
        li:hover{
            cursor:pointer;
            border:1px solid #006AFF;
            border-radius:5px;
            padding:5px;
            
        }
    }

`

const FinishesAndPaints  = styled(PropertyType)``

const RequiredField = styled(PropertyType)``

const RangeBar = styled.div`

    display:flex;
    align-items:center;
    width:45%;
    border:1px solid black;
    border-radius:5px;
    padding-left:10px;
    padding-right:10px;
    @media(max-width:768px){
        margin-bottom:15px;
      }
      @media(max-width:920px){
        margin-bottom:15px;
      }
`

const ResultBtn = styled(SellBtn)`

    margin-top:30px;
    margin-bottom:30px;
    height:30px;
    width:300px;
    font-size:15px;
    color:white;
    @media(max-width:768px){

        width:120px;
        
      }
      @media(max-width:920px){

        width:120px;
        
      }
`
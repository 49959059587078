import axios from 'axios'
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_FAIL,
  USER_DETAILS_RESET,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_Contact_REQUEST, 
  USER_Contact_SUCCESS,
  USER_Contact_SUCCESS_DONE,
  USER_Contact_FAIL

} from '../constants/userConstants'

const StateURL = 'http://estategps.com/admin';


export const login = (email , password , Bearer) => async (dispatch) => {

  try {
     dispatch({
      type: USER_LOGIN_REQUEST,
    })
    const config = {
      headers: {
        "Apipassword" : "mimic2022",
        "lang":"en"
      },
    }
        const {data} = await  axios.post(StateURL+"/client/login",{email,password,Bearer},config);
        

        if(data.status){
          dispatch({type: USER_LOGIN_SUCCESS})
          localStorage.setItem('userInfo', JSON.stringify(data))
          document.location.href='/'

        }else{
         
          dispatch({type: USER_LOGIN_FAIL,payload:data.message});

        }

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response ? error.response.data
          :  "Something went Wrong Please Try Again"
    })
  }
}

export const getVerified = (code) => async (dispatch) => {

  try {
   
                  var FormData = require('form-data');
                  var Userdata = new FormData();

                  Userdata.append('token',code);
                
                  var config = {
                    method: 'post',
                    url: StateURL+'/client/VerifyAccount',
                  headers: { 
                    'Apipassword': 'mimic2022', 
                    'lang': 'en', 
                  },
                  data : Userdata
                  };

                 const {data} = await axios(config)
                 const  {status,type,client,token} = data;
                  
                  // axios(config).then((response)=>{
                  //   console.log(response)
                  // })
                  // console.log("Doing Greate "+ status )
                  if(status){
                    localStorage.setItem('userInfo', JSON.stringify(data))
                    dispatch({type: USER_REGISTER_SUCCESS,payload:data})
                    document.location.href='/'

                  }else{
                    dispatch({type: USER_REGISTER_FAIL,payload:data});

                  }
                  

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response ? error.response.data
          :  "Something went Wrong Please Try Again"
    })
  }
}

export const resendCode = (email) => async (dispatch) => {

  try {
                 var FormData = require('form-data');
                  var Userdata = new FormData();

                  Userdata.append('email',email);
                
                  var config = {
                  method: 'post',
                  url: StateURL+'/client/register',
                  headers: { 
                    'Apipassword': 'mimic2022', 
                    'lang': 'en', 
                  },
                  data : Userdata
                  };

                  const {data} = await axios(config)
                  if(data.status){
                    dispatch({type: USER_REGISTER_SUCCESS,payload:data})
                    document.location.href='/'

                  }else{
                    dispatch({type: USER_REGISTER_FAIL,payload:data});

                  }
                  

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response ? error.response.data
          :  "Something went Wrong Please Try Again"
    })
  }
}


export const Contact = (token ,name  , email , phone , message) => async (dispatch) => {

  try {
                dispatch({
                  type: USER_Contact_REQUEST,
                })

                var FormData = require('form-data');
                var Userdata = new FormData();
                Userdata.append('name', name);
                Userdata.append('email', email);
                Userdata.append('phone', phone);
                Userdata.append('problem', message);
                
                var config = {
                  method: 'post',
                  url: StateURL+ '/client/Message/sendMessageToService',
                  headers: { 
                    'Authorization': `Bearer  ${token}`, 
                    'Apipassword': 'mimic2022', 
                    'lang': 'ar', 
                  },
                  data : Userdata
                };
                
                
                    const {data} =  await axios(config)

             
                  if(data.status){
                    dispatch({type: USER_Contact_SUCCESS,payload:data})
                  }else{
                    dispatch({type: USER_Contact_FAIL,payload:data});

                  }

                  
                  

  } catch (error) {
    dispatch({
      type: USER_Contact_FAIL,
      payload:
        error.response ? error.response.data
          :  "Something went Wrong Please Try Again"
    })
  }
}



export const logout = (token) =>async (dispatch) => {
  
  try {
    localStorage.removeItem('userInfo')

    dispatch({ type: USER_LOGOUT })

     const config = {
      method: 'post',
      url: StateURL+'/client/logout',
      headers: {
        Authorization: `Bearer ${token}`,
        'Apipassword': 'mimic2022', 
        'lang': 'ar'
      },
    }
    
    const  {data}  = await axios.post({},
      config
    )
    dispatch({
      type: USER_DETAILS_RESET,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }

}

import React from 'react'


import {
    Flex,
    Box,
    Image,
    useColorModeValue,
    chakra,
    Tooltip,
    Center
  } from '@chakra-ui/react';
  import {  BsStarFill } from 'react-icons/bs';
  import { AiOutlineHeart } from "react-icons/ai";
  import {MdLocationOn} from 'react-icons/md'
  import { useNavigate } from 'react-router-dom';

  const data = {
    isNew: true,
    imageURL:
      'https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=4600&q=80',
    name: 'Wayfarer Classic',
    price: 4.5,
    rating: 4.2,
    numReviews: 34,
  };
  
  

  function Rating() {
    return (
      <Box d="flex" alignItems="center">
         <Box as="span" mr="2" color="gray.600" fontSize="sm" fontFamily={"Arial"}>
          3.4
        </Box>
        <BsStarFill
          style={{ marginLeft: '1' }}
          color={ '#F9CA24'}
        />
      </Box>
    );
  }
  
export default function Card({id}) {
  const navigate = useNavigate();
  return (
    <Center p={6}>
      <Box
        onClick={()=>{navigate(`/Unit/${id}`)}}
        bg={useColorModeValue('white', 'gray.800')}
        borderWidth="1px"
        maxW={'350px'}
        w={'full'}
        rounded="lg"
        shadow="lg"
        position="relative"
        style={{cursor:'pointer'}}
        >
          
        <AiOutlineHeart className='mark-icon' />
        <Image
          src={require('../../Images/BG.svg').default}
          alt={`Picture of ${data.name}`}
          w={"full"}
          roundedTop="lg"
        />
          <Box
              fontWeight="500"
              as="h4"
              px={6}
              py={2}
              lineHeight="tight"
              isTruncated
              fontFamily={"'Poppins', sans-serif !important"}
          >
              {"Duplex Appartment"}
          </Box>
        <Box px="5" >
            <Flex mt="1" justifyContent="space-between" alignContent="center">
                  <Box
                    as="h4"
                    fontSize={"13px"}
                    lineHeight="tight"
                    isTruncated
                    fontWeight="600"
                    color={"#464646"}
                    fontFamily={"Arial, sans-serif !important"}
                    className="d-flex align-items-center"
                  >
                        <Tooltip
                          label="Location"
                          bg="white"
                          placement={'top'} 
                          color={'gray.800'}
                          fontSize={'1.2em'}>
                            <chakra.a href={'#'} display={'flex'}>
                              <MdLocationOn className="location-icon"/>
                            </chakra.a>
                        </Tooltip>
                        5th settelment , New Cairo
                  </Box>
            </Flex>

          <Flex justifyContent="space-between" mb={5} mt={1} px={1} alignContent="center">
              <div className='row' fontSize="2xl" color={useColorModeValue('gray.800', 'white')}>
                <Box as="span" color={'#006AFF'} fontSize="xl">
                  $ 240\ Mnth
                </Box>
              </div>
              <Rating rating={data.rating} numReviews={data.numReviews} />
          </Flex>
        </Box>
      </Box>
    </Center>
  )
}

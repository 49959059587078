import './App.css';
import Navbar from './components/Header/Navbar';
import Footer from './pages/Footer/footer';
import Home from './pages/home/home';
import Login from './pages/Login/login';
import Register from './pages/register/register';
import ResetPassword from './pages/resetPassword/resetPassword';
import About from './pages/About/About';
import Result from './pages/result/Result';
import ContactUs from './pages/ContactUs/ContactUs';
import Profile from './pages/profile/Profile';
import ResetProfile from './pages/resetProfileInformation/ResetProfile';
import Buy from './pages/Buy/Buy';
import UnitDetails from './pages/unitDetails/unitDetails';
import CompanyDetails from './pages/CompanyDetails/CompanyDetails';
import Companies from './pages/Companies/Companies';
import ForgetPassword from './pages/forgetPassword/forgetPassword';
import Verification from './pages/Verification/Verification';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import HomeEdit from './pages/HomeEdit/Home'
import PrivacyPolicy from './components/PrivacyAndPolicy/PrivacyPolicy';

function App() {
  return (

     <>
              <Router>
                <Routes>
                  <Route path="/" element={<HomeEdit />} />
                  <Route path="/About" element={<About />} />
                  <Route path="/Contact" element={<ContactUs />} />
                  <Route path="/Privacy" element={<PrivacyPolicy />} />
                  <Route path="https://estategps.com/Privacy" element={<PrivacyPolicy />} />
                  <Route path="/Buy" element={<Buy/>} />
                  <Route path="/Company/:id" element={<CompanyDetails/>} />
                  <Route path="/Unit/:id" element={<UnitDetails/>} />
                  <Route path="/Login" element={<Login/>} />
                  <Route path="/Register" element={<Register/>} />
                  <Route path="/ForgetPassword" element={<ForgetPassword/>} />
                  <Route path="/ResetPassword" element={<ResetPassword/>} />
                  <Route path="/Verification" element={<Verification/>} />
                  <Route path="/Companies/" element={<Companies/>} />
                </Routes>
              </Router>
     
     </>
     
  );
}

export default App;

import React , {useState , useEffect} from 'react'
import Navbar from '../../components/Header/Navbar'
import Head from "../../components/CompaniesComponent/Head/Head"
import Footer from "../../components/Footer/Footer"
import Companies from '../../components/CompaniesComponent/Companies/Companies'

export default function Company() {


  return (
    <>
         <Navbar/>
         <Head/>
         <Companies/>
         <Footer/>
    </>
  )
}

import React from 'react'
import {
    Stack,
    Button,
    Text,
    Center,
    useColorModeValue,
    Heading,
    Box,Image
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
const Service = ({ image, text, title  , destination}) => {


  const navigate = useNavigate();
    return (
      <Center py={6}>
        <Box
          maxW={'270px'}
          w={'full'}
          py={5}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'xl'}
          rounded={'md'}
          overflow={'hidden'}>
          <figure> 
            <Image
              h={'150px'}
              w={'full'}
              src={
                require(`../../../Images/${image}`)
              }
              objectFit={''}
            />
          </figure> 
          <Box px={2}>
            <Stack spacing={0} align={'center'} mt={5}>
              <Heading fontSize={'2xl'} fontWeight={600} fontFamily={"'Poppins', sans-serif !important"}>
                {title}
              </Heading>
            <Text px={2} fontSize={{ base: 'sm' }} textAlign={'center'} maxW={'4xl'} fontWeight={400} fontFamily={"'Poppins', sans-serif !important"}  >{text} </Text>
            </Stack>
            <Button
              onClick={()=>{navigate(`/${destination}`)}}
              w={'full'}
              mt={5}
              bg={"#006AFF"}
              color={'white'}
              fontFamily={"'Poppins', sans-serif !important"} 
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
                
              }}>
              Search Homes
            </Button>
          </Box>
        </Box>
    </Center>
    );
  };
  export default Service
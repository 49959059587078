import React , {useState, useEffect}from 'react'
import {
    FormControl,
    FormLabel,
    Flex,
    FormErrorMessage,
    FormHelperText,
    Input,
    Center,
    VStack,
    Button,
    Box,
    
  } from '@chakra-ui/react'
  import {FormValidation} from "../../Validation/FormValidation"
  import { useDispatch, useSelector } from 'react-redux'
  import { Contact } from '../../actions/userActions'
  import Loader from '../Loader';
  import Message from "../Messages"


export default function Form() {


  const dispatch =  useDispatch()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  
  const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : ''
  const token = userInfoFromStorage?.token

  const userContact = useSelector((state) => state.userContact)
  const { loading, error, userInfo } = userContact



  const handleNameChange = (e) => setName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handlePhoneChange = (e) => setPhone(e.target.value)
  const handleMessageChange = (e) => setMessage(e.target.value)



  const submitHandler = (e)=>{
    e.preventDefault()
    dispatch(Contact(token ,  name , email , phone , message))
  }
  

  useEffect(() => {

    if(userInfo?.status){
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
    }

  }, [userInfo])

  return (
    <>
        <Center>
                <Box
                      bg={'white'}
                      padding={'20px'}
                      width={'70vw'} 
                      height={'auto'} 

                      borderWidth={'1px'} 
                      borderRadius={'lg'}

                  >
                        <VStack 
                       
                        align={'center'} 
                        justifyContent={'center'} 
                      >
                          
                          <Center>
                              {loading && <Loader />}
                             
                              {error &&  
                                <>
                                        {Object?.values( error?.message)?.map(err=>(
                                          <>
                                          {
                                            err?.map((e , index)=>(
                                              <Message variant='danger' key={index}>
                                                      <li aria-current="true"  className='me-2'>{e} </li>
                                              </Message>
                                              
                                            ))
                                          }
                                          </>
                                        ))}
                                  </> 
                        }              
                          </Center>
                          <form style={{width:'100%' , height:'100%'}} className='px-2' onSubmit={submitHandler}>
                              <FormControl className='my-2'>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                      type='text'
                                      value={name}
                                      onChange={handleNameChange}
                                      required
                                    />
                                  </FormControl>
                                <FormControl   className='my-2'>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                      type='email'
                                      onChange={handleEmailChange}
                                      value={email}
                                      required
                                      
                                    />
                                   
                                  </FormControl>
                                  <FormControl   className='my-2'>
                                    <FormLabel>Phone</FormLabel>
                                    <Input
                                      type='number'
                                      value={phone}
                                      onChange={handlePhoneChange}
                                      required
                                    />
                                   
                                  </FormControl>
                                  <FormControl  className='my-2'>
                                    <FormLabel>Message</FormLabel>
                                    <Input
                                      type='textarea'
                                      value={message}
                                      onChange={handleMessageChange}
                                      required
                                    />
                                    
                                  </FormControl>
                                  <Center>
                                     <Button colorScheme='blue'  type="submit" fontWeight={'normal'} width={'30vw'}>Send</Button>
                                  </Center>
                          </form>
                              
                    </VStack>
                </Box>
        </Center>
        
    </>
  )
}
